import React, { useState } from "react";
import validator from "validator";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";

import scoboardLogo from "../../../../src/assets/images/scoboard-logo-large.png";
import { useAuth } from "../../../contexts/AuthContext";
import { useHistory } from "react-router";
import { loginAction } from "../../../store/actions/authActions";
import { useDispatch } from "react-redux";
import firebase from "../../../firebase/firebase";
import "firebase/firestore";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://scoboard.com/">
        scoboard.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  scoboardLogo: {
    width: "70%",
  },
  forgotPassword: {
    textAlign: "center",
  },
}));

export default function LogIn() {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [emailLabel, setEmailLabel] = useState("Email Address");
  const [emailErrorState, setEmailErrorState] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordLabel, setPasswordLabel] = useState("Password");
  const [passwordErrorState, setPasswordErrorState] = useState(false);

  const [error, setError] = useState("");
  const { login } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const loginHandler = async (e) => {
    e.preventDefault();

    if (validator.isEmpty(email) === true) {
      setEmailErrorState(true);
      setEmailLabel("Enter Email Address");
      return;
    }

    if (validator.isEmail(email) === false) {
      setEmailErrorState(true);
      setEmailLabel("Enter valid Email Address");
      return;
    }

    if (validator.isEmpty(password) === true) {
      setPasswordErrorState(true);
      setPasswordLabel("Enter Password");
      return;
    }

    try {
      setError("");
      await login(email, password);
      const retClub = async (email) => {
        const db = firebase.firestore();
        let docRef = db.collection("organisation");

        docRef
          .get()
          .then((querySnapshot) => {
            const clubDocumentDetails = {
              clubId: "Arsenal",
              clubName: "Arsenal",
            };
            querySnapshot.forEach((doc) => {
              let clubOwnerArray = doc.data().ClubOwner;
              if (Array.isArray(clubOwnerArray)) {
                clubOwnerArray.forEach((clubOwner) => {
                  if (clubOwner === email) {
                    clubDocumentDetails.clubId = doc.id;
                    clubDocumentDetails.clubName = doc.data().ClubName;
                    dispatch(
                      loginAction(
                        clubDocumentDetails.clubId,
                        email,
                        clubDocumentDetails.clubName
                      )
                    );
                  }
                });
              }
            });
          })
          .catch((error) => console.log(error));
      };
      await retClub(email);
      // await retrieveClub(email);
      // dispatch(loginAction(retreivedClubDetails.clubId));
      history.push("/");
    } catch {
      setError("Email Address and Password combination may be incorrect");
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img
          src={scoboardLogo}
          alt="scoboardLogo"
          className={classes.scoboardLogo}
        />
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <form className={classes.form} noValidate onSubmit={loginHandler}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={emailLabel}
            name="email"
            autoComplete="email"
            autoFocus
            error={emailErrorState}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={passwordLabel}
            type="password"
            id="password"
            autoComplete="current-password"
            error={passwordErrorState}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Log In
          </Button>
          <Grid container>
            <Grid item xs className={classes.forgotPassword}>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
