import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import firebase from "../../firebase/firebase";
import "firebase/firestore";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  basicPadding: {
    padding: theme.spacing(1),
  },
  headerButtonContainer: {
    display: "flex",
  },
  buttonHeight: {
    height: "40px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  textDecorRemoveButton: {
    textDecoration: "none",
    color: "white",
  },
  textDecorRemove: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

const Rivals = () => {
  const classes = useStyles();
  const [rivals, setRivals] = useState([]);
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [rivalDataDisplayState, setRivalDataDisplayState] = useState(false);

  let lsClubID = "Arsenal";

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubID = parsedAuthState.clubID;
  }

  useEffect(() => {
    const fetchSquad = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals");

      docRef
        .get()
        .then((querySnapshot) => {
          const rivalsArray = [];
          querySnapshot.forEach((doc) => {
            rivalsArray.push({ id: doc.id, rivalDetails: doc.data() });
          });
          setRivals(rivalsArray);
          if (Object.keys(rivalsArray).length >= 1) {
            // console.log("length", Object.keys(rivalsArray).length);
            setRivalDataDisplayState(true);
          }
          setLoadingComponent(false);
        })
        .catch((error) => console.log(error));
    };

    fetchSquad();
  }, [lsClubID]);

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {!loadingComponent && (
        <div>
          <div className={classes.headerContainer}>
            <h1>Rivals</h1>
            <div className={classes.headerButtonContainer}>
              <div className={classes.basicPadding}>
                <Link
                  className={classes.textDecorRemoveButton}
                  to="/rivals/add-rival"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.buttonHeight}
                  >
                    Add Rival
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                {rivalDataDisplayState ? (
                  <List>
                    {rivals.map((rival) => (
                      <Link
                        key={rival.id}
                        className={classes.textDecorRemove}
                        to={"/rivals/" + rival.id}
                      >
                        <ListItem button>
                          <ListItemText>
                            {rival.rivalDetails.rivalName}
                          </ListItemText>
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                ) : (
                  <ListItem>
                    {" "}
                    <ListItemText>No data added yet</ListItemText>
                  </ListItem>
                )}
              </Paper>
            </Grid>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Rivals;
