import * as actionTypes from "../actions/actionTypes";

const initialState = [];

const addPlayer = (state, action) => {};

const removePlayer = (state, action) => {};

const editPlayer = (state, action) => {};

const fetchPlayer = (state, action) => {};

const fetchPlayers = (state, action) => {};

const squadReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_PLAYER:
      return addPlayer(state, action);
    case actionTypes.REMOVE_PLAYER:
      return removePlayer(state, action);
    case actionTypes.EDIT_PLAYER:
      return editPlayer(state, action);
    case actionTypes.FETCH_PLAYER:
      return fetchPlayer(state, action);
    case actionTypes.FETCH_PLAYERS:
      return fetchPlayers(state, action);
    default:
      return state;
  }
};

export default squadReducer;
