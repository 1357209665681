import React, { useEffect, useState } from "react";
import firebase from "../../../../firebase/firebase";
import "firebase/firestore";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";

import { timeOptions } from "./utils/timeOptions";

import { cardOptions } from "./utils/cardOptions";

const useStyles = makeStyles((theme) => ({
  teamsContainer: {
    display: "flex",
    paddingBottom: "20px",
  },
  rightPadding: {
    paddingRight: "5px",
  },
  leftPadding: {
    paddingLeft: "5px",
  },
  itemPadding: {
    padding: "10px",
    width: "100%",
  },
  grid: {
    paddingBottom: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixtureHeader: {
    textAlign: "center",
  },
  fixtureDate: {
    color: "rgba(0, 0, 0, 0.60)",
  },
  textDecorRemoveButton: {
    textDecoration: "none",
    color: "white",
    textAlign: "center",
  },
  buttonStyle: {
    height: "40px",
  },
  matchDetails: {
    textAlign: "center",
    justifyContent: "center",
  },
  recordButton: {
    width: "100%",
  },
  buttonAlign: {
    padding: "10px",
  },
  formCenter: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
  alertError: {
    marginBottom: "10px",
  },
}));

const MatchDetails = (props) => {
  const classes = useStyles();
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [fixture, setFixture] = useState({});
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [homeScore, setHomeScore] = useState({
    goalCount: 0,
    goalScorer: "",
    goalTime: "",
  });

  const [rivalScore, setRivalScore] = useState({
    goalCount: 0,
    goalScorer: "",
    goalTime: "",
  });

  const [homeGoalListArray, setHomeGoalListArray] = useState([]);
  const [rivalGoalListArray, setRivalGoalListArray] = useState([]);

  const [homeSubstitutionArray, setHomeSubstitutionArray] = useState([]);
  const [rivalSubstitutionArray, setRivalSubstitutionArray] = useState([]);

  const [homeDisciplinaryArray, setHomeDisciplinaryArray] = useState([]);
  const [rivalDisciplinaryArray, setRivalDisciplinaryArray] = useState([]);

  const [squad, setSquad] = useState([]);
  const [rivalSquad, setRivalSquad] = useState([]);
  const [rivalId, setRivalId] = useState("");
  const [homeGoalScorer, setHomeGoalScorer] = useState("");
  const [homeAssistingPlayer, setHomeAssistingPlayer] = useState("");
  const [rivalGoalScorer, setRivalGoalScorer] = useState("");
  const [rivalAssistingPlayer, setRivalAssistingPlayer] = useState("");
  const [homeGoalTime, setHomeGoalTime] = useState("");
  const [rivalGoalTime, setRivalGoalTime] = useState("");

  const [homePlayerIn, setHomePlayerIn] = useState("");
  const [homePlayerOut, setHomePlayerOut] = useState("");
  const [homeSubstitutionTime, setHomeSubstitutionTime] = useState("");

  const [rivalPlayerIn, setRivalPlayerIn] = useState("");
  const [rivalPlayerOut, setRivalPlayerOut] = useState("");
  const [rivalSubstitutionTime, setRivalSubstitutionTime] = useState("");

  const [homePlayerCardName, setHomePlayerCardName] = useState("");
  const [homePlayerCardType, setHomePlayerCardType] = useState("");
  const [homePlayerCardTime, setHomePlayerCardTime] = useState("");

  const [rivalPlayerCardName, setRivalPlayerCardName] = useState("");
  const [rivalPlayerCardType, setRivalPlayerCardType] = useState("");
  const [rivalPlayerCardTime, setRivalPlayerCardTime] = useState("");
  const [matchDetails, setMatchDetails] = useState({});

  const [playerSelectorDisabledState, setPlayerSelectorDisabledState] =
    useState(false);

  const [
    insufficientNumberOfPlayersAlert,
    setInsufficientNumberOfPlayersAlert,
  ] = useState(false);

  const [
    rivalPlayerSelectorDisabledState,
    setRivalPlayerSelectorDisabledState,
  ] = useState(false);

  const [
    insufficientNumberOfRivalPlayersAlert,
    setInsufficientNumberOfRivalPlayersAlert,
  ] = useState(false);

  let lsClubID = "123";

  let lsClubName = {
    clubName: "Loading...",
  };

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubName.clubName = parsedAuthState.clubName;
    lsClubID = parsedAuthState.clubID;
  }

  const clubName = useSelector((state) => {
    if (state.authReducer.clubName) {
      return state.authReducer.clubName;
    } else {
      return undefined;
    }
  });

  useEffect(() => {
    const fetchFixture = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Fixtures")
        .doc(props.match.params.id);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("Document data:", doc.data());
            setFixture(doc.data());
            setRivalId(doc.data().rivalId);
            setLoadingComponent(false);
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    };
    fetchFixture();
  }, [props, lsClubID]);

  useEffect(() => {
    const fetchSquad = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Squad");

      docRef
        .get()
        .then((querySnapshot) => {
          const squadArray = [];
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            squadArray.push({ id: doc.id, playerDetails: doc.data() });
          });
          setSquad(squadArray);
          if (Object.keys(squadArray).length < 1) {
            // console.log("length", Object.keys(squadArray).length);
            setPlayerSelectorDisabledState(true);
            setInsufficientNumberOfPlayersAlert(true);
          }
          setLoadingComponent(false);
        })
        .catch((error) => console.log(error));
    };
    fetchSquad();
  }, [lsClubID]);

  useEffect(() => {
    const fetchRivalSquad = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals")
        .doc(rivalId)
        .collection("Squad");

      docRef
        .get()
        .then((querySnapshot) => {
          const rivalSquadArray = [];
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            rivalSquadArray.push({ id: doc.id, playerDetails: doc.data() });
          });
          setRivalSquad(rivalSquadArray);
          if (Object.keys(rivalSquadArray).length < 1) {
            // console.log("length", Object.keys(squadArray).length);
            setRivalPlayerSelectorDisabledState(true);
            setInsufficientNumberOfRivalPlayersAlert(true);
          }
          setLoadingComponent(false);
        })
        .catch((error) => console.log(error));
    };
    if (rivalId) {
      fetchRivalSquad();
    }
  }, [rivalId, lsClubID]);

  useEffect(() => {
    setMatchDetails({
      matchDetailsHomeGoalList: homeGoalListArray,
      matchDetailsRivalGoalList: rivalGoalListArray,
      matchDetailsHomeSubList: homeSubstitutionArray,
      matchDetailsRivalSubList: rivalSubstitutionArray,
      matchDetailsHomeDisList: homeDisciplinaryArray,
      matchDetailsRivalDisList: rivalDisciplinaryArray,
      matchDetailsHomeScore: homeScore.goalCount,
      matchDetailsRivalScore: rivalScore.goalCount,
      matchStatus: "finished",
    });
  }, [
    homeGoalListArray,
    rivalGoalListArray,
    homeSubstitutionArray,
    rivalSubstitutionArray,
    homeDisciplinaryArray,
    rivalDisciplinaryArray,
    homeScore,
    rivalScore,
  ]);

  const handleRecordMatchDetails = () => {
    const recordMatchDetailsInFirestore = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Fixtures")
        .doc(props.match.params.id);

      docRef
        .update(matchDetails)
        .then(() => {
          console.log("Document successfully written!");
        })
        .catch((error) => console.log(error));
    };
    recordMatchDetailsInFirestore();
  };

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {insufficientNumberOfPlayersAlert && (
        <Alert className={classes.alertError} severity="error">
          You must add players in your squad to record match details
        </Alert>
      )}
      {insufficientNumberOfRivalPlayersAlert && (
        <Alert className={classes.alertError} severity="error">
          You must add rival players in your rivals squad to record match
          details
        </Alert>
      )}
      {!loadingComponent && (
        <div>
          <div>
            {lsClubName.clubName && fixture.rivalName && (
              <div>
                <Grid item xs={12} className={classes.grid}>
                  <Paper className={classes.paper}>
                    <div className={classes.fixtureHeader}>
                      {fixture.homeOrAway === "home" ? (
                        <h1>
                          {clubName ? clubName : lsClubName.clubName}{" "}
                          {homeScore.goalCount} - {rivalScore.goalCount}{" "}
                          {fixture.rivalName}{" "}
                        </h1>
                      ) : (
                        <h1>
                          {fixture.rivalName} {rivalScore.goalCount} -{" "}
                          {homeScore.goalCount}{" "}
                          {clubName ? clubName : lsClubName.clubName}{" "}
                        </h1>
                      )}
                      <h3 className={classes.fixtureDate}>
                        {new Date(fixture.selectedDate.toDate()).getDate()}{" "}
                        {
                          month[
                            new Date(fixture.selectedDate.toDate()).getMonth()
                          ]
                        }{" "}
                        {new Date(fixture.selectedDate.toDate()).getFullYear()}
                      </h3>
                    </div>
                    <div className={classes.teamsContainer}>
                      <Grid item xs={6} className={classes.rightPadding}>
                        <ul>
                          {homeGoalListArray.map((goal) => {
                            if (goal.goalCount >= 1) {
                              return (
                                <div
                                  key={
                                    Math.random() +
                                    goal.goalScorer +
                                    goal.goalAssist +
                                    goal.goalCount +
                                    goal.goalTime
                                  }
                                >
                                  {"Goal scored by"} {goal.goalScorer}{" "}
                                  {goal.goalTime}
                                  {"'"}
                                  <br></br>
                                  {"Assist by "} {goal.goalAssist}
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </ul>
                        <br></br>
                        <ul>
                          {homeDisciplinaryArray.map((card) => {
                            return (
                              <div
                                key={
                                  Math.random() +
                                  card.playerName +
                                  card.cardType +
                                  card.cardTime
                                }
                              >
                                {card.cardType} {" Card "}
                                {card.playerName} {card.cardTime}
                                {"'"}
                              </div>
                            );
                          })}
                        </ul>
                        <br></br>
                        <ul>
                          {homeSubstitutionArray.map((sub) => {
                            return (
                              <div
                                key={
                                  Math.random() +
                                  sub.playerIn +
                                  sub.playerOut +
                                  sub.substitutionTime
                                }
                              >
                                Player In: {sub.playerIn} / Player Out:{" "}
                                {sub.playerOut} {sub.substitutionTime}
                                {"'"}
                              </div>
                            );
                          })}
                        </ul>
                      </Grid>
                      <Grid item xs={6} className={classes.leftPadding}>
                        <ul>
                          {rivalGoalListArray.map((goal) => {
                            if (goal.goalCount >= 1) {
                              return (
                                <div
                                  key={
                                    Math.random() +
                                    goal.goalScorer +
                                    goal.goalAssist +
                                    goal.goalCount +
                                    goal.goalTime
                                  }
                                >
                                  {"Goal scored by"} {goal.goalScorer}{" "}
                                  {goal.goalTime}
                                  {"'"}
                                  <br></br>
                                  {"Assist by "} {goal.goalAssist}
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </ul>
                        <br></br>
                        <ul>
                          {rivalDisciplinaryArray.map((card) => {
                            return (
                              <div
                                key={
                                  Math.random() +
                                  card.playerName +
                                  card.cardType +
                                  card.cardTime
                                }
                              >
                                {card.cardType} {" Card "}
                                {card.playerName} {card.cardTime}
                                {"'"}
                              </div>
                            );
                          })}
                        </ul>
                        <br></br>
                        <ul>
                          {rivalSubstitutionArray.map((sub) => {
                            return (
                              <div
                                key={
                                  Math.random() +
                                  sub.playerIn +
                                  sub.playerOut +
                                  sub.substitutionTime
                                }
                              >
                                Player In: {sub.playerIn} / Player Out:{" "}
                                {sub.playerOut} {sub.substitutionTime}
                                {"'"}
                              </div>
                            );
                          })}
                        </ul>
                      </Grid>
                    </div>
                    <Link
                      className={classes.textDecorRemoveButton}
                      to={"/fixtures/view-fixture/" + props.match.params.id}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.buttonStyle}
                        onClick={handleRecordMatchDetails}
                      >
                        Record Match Details
                      </Button>
                    </Link>
                  </Paper>
                </Grid>
                <div className={classes.teamsContainer}>
                  <Grid item xs={6} className={classes.rightPadding}>
                    <Paper className={classes.paper}>
                      <h2>My Team's Goal Card</h2>
                      <form className={classes.form} noValidate>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="homeGoalScorer"
                            label="Goal Scorer"
                            select
                            variant="outlined"
                            value={homeGoalScorer}
                            disabled={playerSelectorDisabledState}
                            onChange={(event) => {
                              setHomeGoalScorer(event.target.value);
                            }}
                          >
                            {squad.map((squadPlayer) => (
                              <MenuItem
                                key={squadPlayer.id}
                                value={squadPlayer.playerDetails.firstName}
                              >
                                {squadPlayer.playerDetails.firstName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="homeAssistingPlayer"
                            label="Assist By"
                            select
                            variant="outlined"
                            value={homeAssistingPlayer}
                            disabled={playerSelectorDisabledState}
                            onChange={(event) => {
                              setHomeAssistingPlayer(event.target.value);
                            }}
                          >
                            {squad.map((squadPlayer) => (
                              <MenuItem
                                key={squadPlayer.id}
                                value={squadPlayer.playerDetails.firstName}
                              >
                                {squadPlayer.playerDetails.firstName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="time"
                            label="Time"
                            select
                            variant="outlined"
                            value={homeGoalTime}
                            disabled={playerSelectorDisabledState}
                            onChange={(event) => {
                              setHomeGoalTime(event.target.value);
                            }}
                          >
                            {timeOptions.map((timeOption) => (
                              <MenuItem
                                key={timeOption.value}
                                value={timeOption.label}
                              >
                                {timeOption.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.buttonAlign}>
                            <Button
                              className={classes.recordButton}
                              color={"primary"}
                              variant="outlined"
                              disabled={playerSelectorDisabledState}
                              onClick={() => {
                                if (homeGoalScorer && homeGoalTime) {
                                  setHomeScore({
                                    goalCount: homeScore.goalCount + 1,
                                    goalScorer: homeGoalScorer,
                                    goalTime: homeGoalTime,
                                  });
                                  setHomeGoalListArray([
                                    ...homeGoalListArray,
                                    {
                                      goalCount: homeScore.goalCount + 1,
                                      goalScorer: homeGoalScorer,
                                      goalAssist: homeAssistingPlayer,
                                      goalTime: homeGoalTime,
                                    },
                                  ]);
                                }
                                setHomeGoalScorer("");
                                setHomeAssistingPlayer("");
                                setHomeGoalTime("");
                              }}
                            >
                              Record Goal
                            </Button>
                          </div>
                        </Grid>
                      </form>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} className={classes.leftPadding}>
                    <Paper className={classes.paper}>
                      <h2>Rival Team's Goal Card</h2>
                      <form className={classes.form} noValidate>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="rivalGoalScorer"
                            label="Goal Scorer"
                            select
                            variant="outlined"
                            value={rivalGoalScorer}
                            disabled={rivalPlayerSelectorDisabledState}
                            onChange={(event) => {
                              setRivalGoalScorer(event.target.value);
                            }}
                          >
                            {rivalSquad.map((squadPlayer) => (
                              <MenuItem
                                key={squadPlayer.id}
                                value={squadPlayer.playerDetails.firstName}
                              >
                                {squadPlayer.playerDetails.firstName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="rivalAssistingPlayer"
                            label="Assist By"
                            select
                            variant="outlined"
                            value={rivalAssistingPlayer}
                            disabled={rivalPlayerSelectorDisabledState}
                            onChange={(event) => {
                              setRivalAssistingPlayer(event.target.value);
                            }}
                          >
                            {rivalSquad.map((squadPlayer) => (
                              <MenuItem
                                key={squadPlayer.id}
                                value={squadPlayer.playerDetails.firstName}
                              >
                                {squadPlayer.playerDetails.firstName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="time"
                            label="Time"
                            select
                            variant="outlined"
                            value={rivalGoalTime}
                            disabled={rivalPlayerSelectorDisabledState}
                            onChange={(event) => {
                              setRivalGoalTime(event.target.value);
                            }}
                          >
                            {timeOptions.map((timeOption) => (
                              <MenuItem
                                key={timeOption.value}
                                value={timeOption.label}
                              >
                                {timeOption.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.buttonAlign}>
                            <Button
                              className={classes.recordButton}
                              color={"primary"}
                              variant="outlined"
                              disabled={rivalPlayerSelectorDisabledState}
                              onClick={() => {
                                if (rivalGoalScorer && rivalGoalTime) {
                                  setRivalScore({
                                    goalCount: rivalScore.goalCount + 1,
                                    goalScorer: rivalGoalScorer,
                                    goalTime: rivalGoalTime,
                                  });
                                  setRivalGoalListArray([
                                    ...rivalGoalListArray,
                                    {
                                      goalCount: rivalScore.goalCount + 1,
                                      goalScorer: rivalGoalScorer,
                                      goalAssist: rivalAssistingPlayer,
                                      goalTime: rivalGoalTime,
                                    },
                                  ]);
                                }
                                setRivalGoalScorer("");
                                setRivalAssistingPlayer("");
                                setRivalGoalTime("");
                              }}
                            >
                              Record Goal
                            </Button>
                          </div>
                        </Grid>
                      </form>
                    </Paper>
                  </Grid>
                </div>
                <div className={classes.teamsContainer}>
                  <Grid item xs={6} className={classes.rightPadding}>
                    <Paper className={classes.paper}>
                      <h2>My Team's Substitution Card</h2>
                      <form className={classes.form} noValidate>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="player"
                            label="Player In"
                            select
                            variant="outlined"
                            value={homePlayerIn}
                            disabled={playerSelectorDisabledState}
                            onChange={(event) => {
                              setHomePlayerIn(event.target.value);
                            }}
                          >
                            {squad.map((squadPlayer) => (
                              <MenuItem
                                key={squadPlayer.id}
                                value={squadPlayer.playerDetails.firstName}
                              >
                                {squadPlayer.playerDetails.firstName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="player"
                            label="Player Out"
                            select
                            variant="outlined"
                            value={homePlayerOut}
                            disabled={playerSelectorDisabledState}
                            onChange={(event) => {
                              setHomePlayerOut(event.target.value);
                            }}
                          >
                            {squad.map((squadPlayer) => (
                              <MenuItem
                                key={squadPlayer.id}
                                value={squadPlayer.playerDetails.firstName}
                              >
                                {squadPlayer.playerDetails.firstName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="time"
                            label="Time"
                            select
                            variant="outlined"
                            value={homeSubstitutionTime}
                            disabled={playerSelectorDisabledState}
                            onChange={(event) => {
                              setHomeSubstitutionTime(event.target.value);
                            }}
                          >
                            {timeOptions.map((timeOption) => (
                              <MenuItem
                                key={timeOption.value}
                                value={timeOption.label}
                              >
                                {timeOption.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.buttonAlign}>
                            <Button
                              className={classes.recordButton}
                              color={"primary"}
                              variant="outlined"
                              disabled={playerSelectorDisabledState}
                              onClick={() => {
                                if (
                                  homePlayerIn &&
                                  homePlayerOut &&
                                  homeSubstitutionTime
                                ) {
                                  setHomeSubstitutionArray([
                                    ...homeSubstitutionArray,
                                    {
                                      playerIn: homePlayerIn,
                                      playerOut: homePlayerOut,
                                      substitutionTime: homeSubstitutionTime,
                                    },
                                  ]);
                                }
                                setHomePlayerIn("");
                                setHomePlayerOut("");
                                setHomeSubstitutionTime("");
                              }}
                            >
                              Record Substitution
                            </Button>
                          </div>
                        </Grid>
                      </form>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} className={classes.rightPadding}>
                    <Paper className={classes.paper}>
                      <h2>My Rival Team's Substitution Card</h2>
                      <form className={classes.form} noValidate>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="player"
                            label="Player In"
                            select
                            variant="outlined"
                            value={rivalPlayerIn}
                            disabled={rivalPlayerSelectorDisabledState}
                            onChange={(event) => {
                              setRivalPlayerIn(event.target.value);
                            }}
                          >
                            {rivalSquad.map((squadPlayer) => (
                              <MenuItem
                                key={squadPlayer.id}
                                value={squadPlayer.playerDetails.firstName}
                              >
                                {squadPlayer.playerDetails.firstName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="player"
                            label="Player Out"
                            select
                            variant="outlined"
                            value={rivalPlayerOut}
                            disabled={rivalPlayerSelectorDisabledState}
                            onChange={(event) => {
                              setRivalPlayerOut(event.target.value);
                            }}
                          >
                            {rivalSquad.map((squadPlayer) => (
                              <MenuItem
                                key={squadPlayer.id}
                                value={squadPlayer.playerDetails.firstName}
                              >
                                {squadPlayer.playerDetails.firstName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="time"
                            label="Time"
                            select
                            variant="outlined"
                            value={homeSubstitutionTime}
                            disabled={rivalPlayerSelectorDisabledState}
                            onChange={(event) => {
                              setRivalSubstitutionTime(event.target.value);
                            }}
                          >
                            {timeOptions.map((timeOption) => (
                              <MenuItem
                                key={timeOption.value}
                                value={timeOption.label}
                              >
                                {timeOption.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.buttonAlign}>
                            <Button
                              className={classes.recordButton}
                              color={"primary"}
                              variant="outlined"
                              disabled={rivalPlayerSelectorDisabledState}
                              onClick={() => {
                                if (
                                  rivalPlayerIn &&
                                  rivalPlayerOut &&
                                  rivalSubstitutionTime
                                ) {
                                  setRivalSubstitutionArray([
                                    ...rivalSubstitutionArray,
                                    {
                                      playerIn: rivalPlayerIn,
                                      playerOut: rivalPlayerOut,
                                      substitutionTime: rivalSubstitutionTime,
                                    },
                                  ]);
                                }
                                setRivalPlayerIn("");
                                setRivalPlayerOut("");
                                setRivalSubstitutionTime("");
                              }}
                            >
                              Record Substitution
                            </Button>
                          </div>
                        </Grid>
                      </form>
                    </Paper>
                  </Grid>
                </div>
                <div className={classes.teamsContainer}>
                  <Grid item xs={6} className={classes.rightPadding}>
                    <Paper className={classes.paper}>
                      <h2>My Team's Disciplinary Card</h2>
                      <form className={classes.form} noValidate>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="player"
                            label="Player Name"
                            select
                            variant="outlined"
                            value={homePlayerCardName}
                            disabled={playerSelectorDisabledState}
                            onChange={(event) => {
                              setHomePlayerCardName(event.target.value);
                            }}
                          >
                            {squad.map((squadPlayer) => (
                              <MenuItem
                                key={squadPlayer.id}
                                value={squadPlayer.playerDetails.firstName}
                              >
                                {squadPlayer.playerDetails.firstName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="homeCard"
                            label="Card Type"
                            select
                            variant="outlined"
                            value={homePlayerCardType}
                            disabled={playerSelectorDisabledState}
                            onChange={(event) => {
                              setHomePlayerCardType(event.target.value);
                            }}
                          >
                            {cardOptions.map((card) => (
                              <MenuItem key={card.value} value={card.value}>
                                {card.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="time"
                            label="Time"
                            select
                            variant="outlined"
                            value={homePlayerCardTime}
                            disabled={playerSelectorDisabledState}
                            onChange={(event) => {
                              setHomePlayerCardTime(event.target.value);
                            }}
                          >
                            {timeOptions.map((timeOption) => (
                              <MenuItem
                                key={timeOption.value}
                                value={timeOption.label}
                              >
                                {timeOption.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.buttonAlign}>
                            <Button
                              className={classes.recordButton}
                              color={"primary"}
                              variant="outlined"
                              disabled={playerSelectorDisabledState}
                              onClick={() => {
                                if (
                                  homePlayerCardName &&
                                  homePlayerCardType &&
                                  homePlayerCardTime
                                ) {
                                  setHomeDisciplinaryArray([
                                    ...homeDisciplinaryArray,
                                    {
                                      playerName: homePlayerCardName,
                                      cardType: homePlayerCardType,
                                      cardTime: homePlayerCardTime,
                                    },
                                  ]);
                                }
                                setHomePlayerCardName("");
                                setHomePlayerCardType("");
                                setHomePlayerCardTime("");
                              }}
                            >
                              Record Disciplinary Action
                            </Button>
                          </div>
                        </Grid>
                      </form>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} className={classes.rightPadding}>
                    <Paper className={classes.paper}>
                      <h2>My Rival Team's Disciplinary Card</h2>
                      <form className={classes.form} noValidate>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="player"
                            label="Player Name"
                            select
                            variant="outlined"
                            value={rivalPlayerCardName}
                            disabled={rivalPlayerSelectorDisabledState}
                            onChange={(event) => {
                              setRivalPlayerCardName(event.target.value);
                            }}
                          >
                            {rivalSquad.map((squadPlayer) => (
                              <MenuItem
                                key={squadPlayer.id}
                                value={squadPlayer.playerDetails.firstName}
                              >
                                {squadPlayer.playerDetails.firstName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="player"
                            label="Card Type"
                            select
                            variant="outlined"
                            value={rivalPlayerCardType}
                            disabled={rivalPlayerSelectorDisabledState}
                            onChange={(event) => {
                              setRivalPlayerCardType(event.target.value);
                            }}
                          >
                            {cardOptions.map((card) => (
                              <MenuItem key={card.value} value={card.value}>
                                {card.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.itemPadding}
                            id="time"
                            label="Time"
                            select
                            variant="outlined"
                            value={rivalPlayerCardTime}
                            disabled={rivalPlayerSelectorDisabledState}
                            onChange={(event) => {
                              setRivalPlayerCardTime(event.target.value);
                            }}
                          >
                            {timeOptions.map((timeOption) => (
                              <MenuItem
                                key={timeOption.value}
                                value={timeOption.label}
                              >
                                {timeOption.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.buttonAlign}>
                            <Button
                              className={classes.recordButton}
                              color={"primary"}
                              variant="outlined"
                              disabled={rivalPlayerSelectorDisabledState}
                              onClick={() => {
                                if (
                                  rivalPlayerCardName &&
                                  rivalPlayerCardType &&
                                  rivalPlayerCardTime
                                ) {
                                  setRivalDisciplinaryArray([
                                    ...rivalDisciplinaryArray,
                                    {
                                      playerName: rivalPlayerCardName,
                                      cardType: rivalPlayerCardType,
                                      cardTime: rivalPlayerCardTime,
                                    },
                                  ]);
                                }
                                setRivalPlayerCardName("");
                                setRivalPlayerCardType("");
                                setRivalPlayerCardTime("");
                              }}
                            >
                              Record Disciplinary Action
                            </Button>
                          </div>
                        </Grid>
                      </form>
                    </Paper>
                  </Grid>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MatchDetails;
