import authReducer from "./authReducer";
import squadReducer from "./squadReducer";
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

const rootReducer = combineReducers({
  authReducer,
  squadReducer,
  firestoreReducer,
  firebaseReducer,
});

export default rootReducer;
