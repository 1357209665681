export const formationOptions = [
  {
    value: "4 - 4 - 2",
    label: "4 - 4 - 2",
  },
  {
    value: "4 - 3 - 3",
    label: "4 - 3 - 3",
  },
  {
    value: "4 - 2 - 3 - 1",
    label: "4 - 2 - 3 - 1",
  },
  {
    value: "4 - 1 - 2 - 1 - 2",
    label: "4 - 1 - 2 - 1 - 2",
  },
  {
    value: "4 - 1 - 4 - 1",
    label: "4 - 1 - 4 - 1",
  },
  {
    value: "3 - 4 - 3",
    label: "3 - 4 - 3",
  },
  {
    value: "3 - 5 - 2",
    label: "3 - 5 - 2",
  },
];
