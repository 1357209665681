import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import firebase from "../../../firebase/firebase";
import "firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixtureHeader: {
    textAlign: "center",
  },
  fixtureDate: {
    color: "rgba(0, 0, 0, 0.60)",
  },
  buttonHeight: {
    height: "40px",
    marginLeft: "10px",
    marginRight: "10px",
  },
  matchDetails: {
    textAlign: "center",
    justifyContent: "center",
  },
  teamsContainer: {
    display: "flex",
  },
  rightPadding: {
    paddingRight: "5px",
  },
  leftPadding: {
    paddingLeft: "5px",
  },
  itemPadding: {
    padding: "10px",
    width: "100%",
  },
  grid: {
    paddingBottom: "20px",
  },
  textDecorRemoveButton: {
    textDecoration: "none",
    color: "white",
    textAlign: "center",
  },
  buttonStyle: {
    height: "40px",
  },
  recordButton: {
    width: "100%",
  },
  buttonAlign: {
    padding: "10px",
  },
  formCenter: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
}));

const ViewFixture = (props) => {
  const classes = useStyles();
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [fixture, setFixture] = useState({});

  const [homeGoalListArray, setHomeGoalListArray] = useState([]);
  const [rivalGoalListArray, setRivalGoalListArray] = useState([]);

  const [homeSubstitutionArray, setHomeSubstitutionArray] = useState([]);
  const [rivalSubstitutionArray, setRivalSubstitutionArray] = useState([]);

  const [homeDisciplinaryArray, setHomeDisciplinaryArray] = useState([]);
  const [rivalDisciplinaryArray, setRivalDisciplinaryArray] = useState([]);

  const [homeScore, setHomeScore] = useState(0);
  const [rivalScore, setRivalScore] = useState(0);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let lsClubID = "123";

  let lsClubName = {
    clubName: "Loading...",
  };

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubName.clubName = parsedAuthState.clubName;
    lsClubID = parsedAuthState.clubID;
  }

  const clubName = useSelector((state) => {
    if (state.authReducer.clubName) {
      return state.authReducer.clubName;
    } else {
      return undefined;
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Fixtures")
        .doc(props.match.params.id);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            setFixture(doc.data());
            setHomeGoalListArray(doc.data().matchDetailsHomeGoalList);
            setRivalGoalListArray(doc.data().matchDetailsRivalGoalList);

            setHomeSubstitutionArray(doc.data().matchDetailsHomeSubList);
            setRivalSubstitutionArray(doc.data().matchDetailsRivalSubList);

            setHomeDisciplinaryArray(doc.data().matchDetailsHomeDisList);
            setRivalDisciplinaryArray(doc.data().matchDetailsRivalDisList);

            setHomeScore(doc.data().matchDetailsHomeScore);
            setRivalScore(doc.data().matchDetailsRivalScore);
            setLoadingComponent(false);
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    };
    fetchData();
  }, [props, lsClubID]);

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {!loadingComponent && (
        <div>
          <div>
            {lsClubName.clubName && fixture.rivalName && (
              <div>
                <Grid item xs={12}>
                  <Paper className={classes.paper}>
                    <div className={classes.fixtureHeader}>
                      {fixture.matchStatus === "notFinished" ? (
                        <div>
                          {fixture.homeOrAway === "home" ? (
                            <h1>
                              {clubName ? clubName : lsClubName.clubName} vs{" "}
                              {fixture.rivalName}{" "}
                              <Link
                                className={classes.textDecorRemoveButton}
                                to={
                                  "/fixtures/edit-fixture/" +
                                  props.match.params.id
                                }
                              >
                                <IconButton
                                  color="primary"
                                  className={classes.buttonHeight}
                                >
                                  <EditOutlinedIcon />
                                </IconButton>
                              </Link>
                            </h1>
                          ) : (
                            <h1>
                              {fixture.rivalName} vs{" "}
                              {clubName ? clubName : lsClubName.clubName}{" "}
                              <Link
                                className={classes.textDecorRemoveButton}
                                to={
                                  "/fixtures/edit-fixture/" +
                                  props.match.params.id
                                }
                              >
                                <IconButton
                                  color="primary"
                                  className={classes.buttonHeight}
                                >
                                  <EditOutlinedIcon />
                                </IconButton>
                              </Link>
                            </h1>
                          )}
                        </div>
                      ) : (
                        <div>
                          <div className={classes.fixtureHeader}>
                            {fixture.homeOrAway === "home" ? (
                              <h1>
                                {clubName ? clubName : lsClubName.clubName}{" "}
                                {homeScore} - {rivalScore} {fixture.rivalName}{" "}
                                <Link
                                  className={classes.textDecorRemoveButton}
                                  to={
                                    "/fixtures/edit-fixture/" +
                                    props.match.params.id
                                  }
                                >
                                  <IconButton
                                    color="primary"
                                    className={classes.buttonHeight}
                                  >
                                    <EditOutlinedIcon />
                                  </IconButton>
                                </Link>
                              </h1>
                            ) : (
                              <h1>
                                {fixture.rivalName} {rivalScore} - {homeScore}{" "}
                                {clubName ? clubName : lsClubName.clubName}{" "}
                                <Link
                                  className={classes.textDecorRemoveButton}
                                  to={
                                    "/fixtures/edit-fixture/" +
                                    props.match.params.id
                                  }
                                >
                                  <IconButton
                                    color="primary"
                                    className={classes.buttonHeight}
                                  >
                                    <EditOutlinedIcon />
                                  </IconButton>
                                </Link>
                              </h1>
                            )}
                            <h3 className={classes.fixtureDate}>
                              {new Date(
                                fixture.selectedDate.toDate()
                              ).getDate()}{" "}
                              {
                                month[
                                  new Date(
                                    fixture.selectedDate.toDate()
                                  ).getMonth()
                                ]
                              }{" "}
                              {new Date(
                                fixture.selectedDate.toDate()
                              ).getFullYear()}
                            </h3>
                          </div>
                          <div className={classes.teamsContainer}>
                            <Grid item xs={6} className={classes.rightPadding}>
                              <ul>
                                {homeGoalListArray.map((goal) => {
                                  if (goal.goalCount >= 1) {
                                    return (
                                      <div
                                        key={
                                          Math.random() +
                                          goal.goalScorer +
                                          goal.goalAssist +
                                          goal.goalCount +
                                          goal.goalTime
                                        }
                                      >
                                        {"Goal scored by"} {goal.goalScorer}{" "}
                                        {goal.goalTime}
                                        {"'"}
                                        <br></br>
                                        {"Assist by "} {goal.goalAssist}
                                      </div>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </ul>
                              <br></br>
                              <ul>
                                {homeDisciplinaryArray.map((card) => {
                                  return (
                                    <div
                                      key={
                                        Math.random() +
                                        card.playerName +
                                        card.cardType +
                                        card.cardTime
                                      }
                                    >
                                      {card.cardType} {" Card "}
                                      {card.playerName} {card.cardTime}
                                      {"'"}
                                    </div>
                                  );
                                })}
                              </ul>
                              <br></br>
                              <ul>
                                {homeSubstitutionArray.map((sub) => {
                                  return (
                                    <div
                                      key={
                                        Math.random() +
                                        sub.playerIn +
                                        sub.playerOut +
                                        sub.substitutionTime
                                      }
                                    >
                                      Player In: {sub.playerIn} / Player Out:{" "}
                                      {sub.playerOut} {sub.substitutionTime}
                                      {"'"}
                                    </div>
                                  );
                                })}
                              </ul>
                            </Grid>
                            <Grid item xs={6} className={classes.leftPadding}>
                              <ul>
                                {rivalGoalListArray.map((goal) => {
                                  if (goal.goalCount >= 1) {
                                    return (
                                      <div
                                        key={
                                          Math.random() +
                                          goal.goalScorer +
                                          goal.goalAssist +
                                          goal.goalCount +
                                          goal.goalTime
                                        }
                                      >
                                        {"Goal scored by"} {goal.goalScorer}{" "}
                                        {goal.goalTime}
                                        {"'"}
                                        <br></br>
                                        {"Assist by "} {goal.goalAssist}
                                      </div>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </ul>
                              <br></br>
                              <ul>
                                {rivalDisciplinaryArray.map((card) => {
                                  return (
                                    <div
                                      key={
                                        Math.random() +
                                        card.playerName +
                                        card.cardType +
                                        card.cardTime
                                      }
                                    >
                                      {card.cardType} {" Card "}
                                      {card.playerName} {card.cardTime}
                                      {"'"}
                                    </div>
                                  );
                                })}
                              </ul>
                              <br></br>
                              <ul>
                                {rivalSubstitutionArray.map((sub) => {
                                  return (
                                    <div
                                      key={
                                        Math.random() +
                                        sub.playerIn +
                                        sub.playerOut +
                                        sub.substitutionTime
                                      }
                                    >
                                      Player In: {sub.playerIn} / Player Out:{" "}
                                      {sub.playerOut} {sub.substitutionTime}
                                      {"'"}
                                    </div>
                                  );
                                })}
                              </ul>
                            </Grid>
                          </div>
                        </div>
                      )}
                    </div>

                    {fixture.matchStatus === "notFinished" ? (
                      <div className={classes.matchDetails}>
                        <h3>Match details has not been added</h3>
                        <Link
                          className={classes.textDecorRemoveButton}
                          to={
                            "/fixtures/match-details/" + props.match.params.id
                          }
                        >
                          <Button
                            variant="contained"
                            color="secondary"
                            className={classes.buttonHeight}
                          >
                            Add Match Details
                          </Button>
                        </Link>
                        <Link
                          className={classes.textDecorRemoveButton}
                          to={"/fixtures/match-stats/" + props.match.params.id}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonHeight}
                          >
                            Add Match Stats
                          </Button>
                        </Link>
                      </div>
                    ) : (
                      <div className={classes.matchDetails}>
                        <h3>The Match Has Completed</h3>
                        <Link
                          className={classes.textDecorRemoveButton}
                          to={
                            "/fixtures/match-details/" + props.match.params.id
                          }
                        >
                          <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.buttonHeight}
                          >
                            Edit Match Details
                          </Button>
                        </Link>
                        <Link
                          className={classes.textDecorRemoveButton}
                          to={"/fixtures/match-stats/" + props.match.params.id}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            className={classes.buttonHeight}
                          >
                            Edit Match Stats
                          </Button>
                        </Link>
                      </div>
                    )}
                  </Paper>
                </Grid>
              </div>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ViewFixture;
