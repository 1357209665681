import * as actionTypes from "../actions/actionTypes";

const initialState = [];

const loginReducer = (state, action) => {
  const authState = {
    clubID: action.clubID,
    userEmail: action.userEmail,
    clubName: action.clubName,
  };

  localStorage.setItem("authState", JSON.stringify(authState));
  return authState;
};

const logoutReducer = (state = initialState) => {
  return state;
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return loginReducer(state, action);
    case actionTypes.LOGOUT:
      return logoutReducer(state);
    default:
      return state;
  }
};

export default authReducer;
