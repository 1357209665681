import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import validator from "validator";

import firebase from "../../../firebase/firebase";
import "firebase/firestore";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  buttonHeight: {
    height: "40px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
  },
  headerButtonContainer: {
    display: "flex",
  },
  textField: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  selectField: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  basicPadding: {
    padding: theme.spacing(1),
  },
  textDecorRemoveButton: {
    textDecoration: "none",
    color: "white",
  },
}));

const AddRival = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [rivalName, setRivalName] = useState("");
  const [rivalNameErrorState, setRivalNameErrorState] = useState(false);
  const [rivalCountry, setRivalCountry] = useState("");
  const [groundName, setGroundName] = useState("");
  const [managerName, setManagerName] = useState("");

  const [rivalDetails, setRivalDetails] = useState({});

  let lsClubID = "Arsenal";

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubID = parsedAuthState.clubID;
  }

  useEffect(() => {
    setLoadingComponent(false);
  }, []);

  useEffect(() => {
    setRivalDetails({
      rivalName: rivalName,
      rivalCountry: rivalCountry,
      groundName: groundName,
      managerName: managerName,
    });
  }, [rivalName, rivalCountry, groundName, managerName]);

  const handleRivalCreate = (e) => {
    e.preventDefault();
    console.log(rivalDetails);
    if (validator.isEmpty(rivalName) === true) {
      setRivalNameErrorState(true);
      return;
    }

    const createRivalInFirestore = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals");

      docRef
        .add(rivalDetails)
        .then(() => {
          console.log("Document successfully written!");
          history.push("/rivals");
        })
        .catch((error) => console.log(error));
    };
    createRivalInFirestore();
  };

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {!loadingComponent && (
        <div>
          <div className={classes.headerContainer}>
            <h1>Add Rival</h1>
            <div className={classes.headerButtonContainer}>
              <div className={classes.basicPadding}>
                <Link className={classes.textDecorRemoveButton} to="/rivals">
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.buttonHeight}
                    onClick={handleRivalCreate}
                  >
                    Create Rival
                  </Button>
                </Link>
              </div>
              <div className={classes.basicPadding}>
                <Link className={classes.textDecorRemoveButton} to="/rivals">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonHeight}
                  >
                    Cancel
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <Grid item xs={5}>
              <Paper className={classes.paper}>
                <form className={classes.form} noValidate>
                  <TextField
                    className={classes.basicPadding}
                    variant="outlined"
                    margin="normal"
                    required
                    id="rivalClubName"
                    label="Rival Club Name"
                    name="rivalClubName"
                    autoComplete="text"
                    error={rivalNameErrorState}
                    autoFocus
                    onChange={(event) => {
                      setRivalName(event.target.value);
                    }}
                  />
                  <TextField
                    className={classes.basicPadding}
                    variant="outlined"
                    margin="normal"
                    id="country"
                    label="Country"
                    name="country"
                    autoComplete="text"
                    onChange={(event) => {
                      setRivalCountry(event.target.value);
                    }}
                  />
                  <TextField
                    className={classes.basicPadding}
                    variant="outlined"
                    margin="normal"
                    id="groundName"
                    label="Ground Name"
                    name="groundName"
                    autoComplete="text"
                    onChange={(event) => {
                      setGroundName(event.target.value);
                    }}
                  />
                  <TextField
                    className={classes.basicPadding}
                    variant="outlined"
                    margin="normal"
                    id="managerName"
                    label="Manager Name"
                    name="managerName"
                    autoComplete="text"
                    onChange={(event) => {
                      setManagerName(event.target.value);
                    }}
                  />
                </form>
              </Paper>
            </Grid>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AddRival;
