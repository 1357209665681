import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
  },
  headerButtonContainer: {
    display: "flex",
  },
  basicPadding: {
    padding: theme.spacing(1),
  },
  textDecorRemoveButton: {
    textDecoration: "none",
    color: "white",
  },
  buttonHeight: {
    height: "40px",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
}));

const ClubDetails = () => {
  const classes = useStyles();
  const [loadingComponent, setLoadingComponent] = useState(true);

  let lsClubDetails = {
    clubName: "Loading...",
    lsClubID: "Arsenal",
    userEmail: "prithvirajkumar14@gmail.com",
  };

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubDetails.clubName = parsedAuthState.clubName;
    lsClubDetails.lsClubID = parsedAuthState.clubID;
    lsClubDetails.userEmail = parsedAuthState.userEmail;
  }

  const clubDetails = useSelector((state) => {
    console.log(state);
    if (state.authReducer.clubName) {
      return {
        clubName: state.authReducer.clubName,
        clubID: state.authReducer.clubID,
        userEmail: state.authReducer.userEmail,
      };
    } else {
      return undefined;
    }
  });

  useEffect(() => {
    setLoadingComponent(false);
  }, []);

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {!loadingComponent && (
        <div>
          <div className={classes.headerContainer}>
            <h1>Club Details</h1>
            <div className={classes.headerButtonContainer}>
              <div className={classes.basicPadding}>
                <Link
                  className={classes.textDecorRemoveButton}
                  to="/settings/edit-club-details/"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.buttonHeight}
                  >
                    Edit Club Details
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <Grid item xs={5}>
              <Paper className={classes.paper}>
                <List aria-label="settings">
                  <ListItem>
                    <ListItemText>
                      {"Club Name: "}
                      {clubDetails
                        ? clubDetails.clubName
                        : lsClubDetails.clubName}
                    </ListItemText>
                  </ListItem>
                </List>
              </Paper>
            </Grid>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ClubDetails;
