import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import firebase from "../../firebase/firebase";
import "firebase/firestore";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  buttonHeight: {
    height: "40px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
  },
  headerButtonContainer: {
    display: "flex",
  },
  basicPadding: {
    padding: theme.spacing(1),
  },
  textDecorRemoveButton: {
    textDecoration: "none",
    color: "white",
  },
  listItemStyles: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
  },
  dateStyles: {
    fontSize: "13px",
  },
}));

const Fixtures = () => {
  const classes = useStyles();
  const [fixture, setFixture] = useState([]);
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [fixtureDataDisplayState, setFixtureDataDisplayState] = useState(false);

  let lsClubID = "123";

  let lsClubName = {
    clubName: "Loading...",
  };

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubName.clubName = parsedAuthState.clubName;
    lsClubID = parsedAuthState.clubID;
  }

  const clubName = useSelector((state) => {
    if (state.authReducer.clubName) {
      return state.authReducer.clubName;
    } else {
      return undefined;
    }
  });

  useEffect(() => {
    const fetchFixture = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Fixtures");

      docRef
        .get()
        .then((querySnapshot) => {
          const fixtureArray = [];
          querySnapshot.forEach((doc) => {
            // console.log(doc.id, " => ", doc.data());
            fixtureArray.push({ id: doc.id, fixtureDetails: doc.data() });
          });
          setFixture(fixtureArray);
          if (Object.keys(fixtureArray).length >= 1) {
            // console.log("length", Object.keys(fixtureArray).length);
            setFixtureDataDisplayState(true);
          }
          setLoadingComponent(false);
        })
        .catch((error) => console.log(error));
    };

    fetchFixture();
  }, [lsClubID]);

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {!loadingComponent && (
        <div>
          <div className={classes.headerContainer}>
            <h1>Fixtures</h1>
            <div className={classes.headerButtonContainer}>
              <div className={classes.basicPadding}>
                <Link
                  className={classes.textDecorRemoveButton}
                  to="/fixtures/add-fixture"
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.buttonHeight}
                  >
                    Add Fixture
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <Grid item xs={5}>
              <Paper className={classes.paper}>
                {fixtureDataDisplayState ? (
                  <List>
                    {fixture.map((fixture) => {
                      let timestamp =
                        fixture.fixtureDetails.selectedDate.toDate();
                      let month = [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                      ];

                      let date = new Date(timestamp);
                      let fixtureMonth = month[date.getMonth()];
                      // console.log(fixture.fixtureDetails.selectedDate);

                      if (fixture.fixtureDetails.homeOrAway === "home") {
                        return (
                          <Link
                            key={fixture.id}
                            className={classes.listItemStyles}
                            to={"/fixtures/view-fixture/" + fixture.id}
                          >
                            <ListItem button>
                              <ListItemText>
                                <div className={classes.dateStyles}>
                                  {fixtureMonth.substring(0, 3)}{" "}
                                  {date.getDate()}{" "}
                                  {/* {fixture.fixtureDetails.selectedDate.toDate().substring(3, 14)} */}
                                </div>
                                {clubName ? clubName : lsClubName.clubName} vs{" "}
                                {fixture.fixtureDetails.rivalName}
                              </ListItemText>
                            </ListItem>
                          </Link>
                        );
                      } else {
                        return (
                          <Link
                            key={fixture.id}
                            className={classes.listItemStyles}
                            to={"/fixtures/view-fixture/" + fixture.id}
                          >
                            <ListItem button>
                              <ListItemText>
                                <div className={classes.dateStyles}>
                                  {fixtureMonth.substring(0, 3)}{" "}
                                  {date.getDate()}{" "}
                                </div>
                                {fixture.fixtureDetails.rivalName} vs{" "}
                                {clubName ? clubName : lsClubName.clubName}
                              </ListItemText>
                            </ListItem>
                          </Link>
                        );
                      }
                    })}
                  </List>
                ) : (
                  <ListItem>
                    {" "}
                    <ListItemText>No data added yet</ListItemText>
                  </ListItem>
                )}
              </Paper>
            </Grid>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Fixtures;
