import React, { useEffect, useState } from "react";
import firebase from "../../../../firebase/firebase";
import "firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  itemPadding: {
    padding: "10px",
    width: "100%",
  },
  grid: {
    paddingBottom: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formCenter: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
  },
  recordButton: {
    width: "100%",
  },
  buttonAlign: {
    padding: "10px",
  },
}));

const MatchStats = (props) => {
  const classes = useStyles();
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [myTeamPosession, setMyTeamPosession] = useState("");
  const [myTeamTotalShots, setMyTeamTotalShots] = useState("");
  const [myTeamChancesCreated, setMyTeamChancesCreated] = useState("");
  const [myTeamTotalAccuratePasses, setMyTeamTotalAccuratePasses] =
    useState("");
  const [myTeamPassSuccessRate, setMyTeamPassSuccessRate] = useState("");
  const [myTeamFoulsConceded, setMyTeamFoulsConceded] = useState("");
  const [myTeamCorners, setMyTeamCorners] = useState("");
  const [myTeamOffsides, setMyTeamOffsides] = useState("");

  const [rivalPosession, setRivalPosession] = useState("");
  const [rivalTotalShots, setRivalTotalShots] = useState("");
  const [rivalChancesCreated, setRivalChancesCreated] = useState("");
  const [rivalTotalAccuratePasses, setRivalTotalAccuratePasses] = useState("");
  const [rivalPassSuccessRate, setRivalPassSuccessRate] = useState("");
  const [rivalFoulsConceded, setRivalFoulsConceded] = useState("");
  const [rivalCorners, setRivalCorners] = useState("");
  const [rivalOffsides, setRivalOffsides] = useState("");

  let lsClubID = "Arsenal";

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubID = parsedAuthState.clubID;
  }

  useEffect(() => {
    setLoadingComponent(false);
  }, []);

  const recordMatchStats = () => {
    const matchStats = {
      myTeamPosession,
      myTeamTotalShots,
      myTeamChancesCreated,
      myTeamTotalAccuratePasses,
      myTeamPassSuccessRate,
      myTeamFoulsConceded,
      myTeamCorners,
      myTeamOffsides,
      rivalPosession,
      rivalTotalShots,
      rivalChancesCreated,
      rivalTotalAccuratePasses,
      rivalPassSuccessRate,
      rivalFoulsConceded,
      rivalCorners,
      rivalOffsides,
    };

    const recordMatchStatsInFirestore = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Fixtures")
        .doc(props.match.params.id);

      docRef
        .update(matchStats)
        .then(() => {
          console.log("Document successfully written!");
        })
        .catch((error) => console.log(error));
    };
    recordMatchStatsInFirestore();
  };

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {!loadingComponent && (
        <div>
          <Grid item xs={12} className={classes.grid}>
            <Paper className={classes.paper}>
              <h2>Match Stats Card</h2>
              <div className={classes.formCenter}>
                <Grid item xs={6}>
                  <form className={classes.form} noValidate>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="myTeamPossession"
                        label="My Team's Possession"
                        name="myTeamPossession"
                        type="number"
                        onChange={(event) => {
                          setMyTeamPosession(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="myTeamTotalShots"
                        label="My Team's Total Shots"
                        name="myTeamTotalShots"
                        type="number"
                        onChange={(event) => {
                          setMyTeamTotalShots(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="myTeamChancesCreated"
                        label="My Team's Chances Created"
                        name="myTeamChancesCreated"
                        type="number"
                        onChange={(event) => {
                          setMyTeamChancesCreated(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="myTeamTotalAccuratePasses"
                        label="My Team's Total Accurate Passes"
                        name="myTeamTotalAccuratePasses"
                        type="number"
                        onChange={(event) => {
                          setMyTeamTotalAccuratePasses(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="myTeamPassSuccessRate"
                        label="My Team's Pass Success Rate"
                        name="myTeamPassSuccessRate"
                        type="number"
                        onChange={(event) => {
                          setMyTeamPassSuccessRate(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="myTeamFoulsConceded"
                        label="My Team Fouls Conceded"
                        name="myTeamFoulsConceded"
                        type="number"
                        onChange={(event) => {
                          setMyTeamFoulsConceded(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="myTeamCorners"
                        label="My Team's Corners"
                        name="myTeamCorners"
                        type="number"
                        onChange={(event) => {
                          setMyTeamCorners(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="myTeamOffsides"
                        label="My Team's Offsides"
                        name="myTeamOffsides"
                        type="number"
                        onChange={(event) => {
                          setMyTeamOffsides(event.target.value);
                        }}
                      />
                    </Grid>
                  </form>
                </Grid>
                <Grid item xs={6}>
                  <form className={classes.form} noValidate>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="rivalTeamPossession"
                        label="Rival Team's Possession"
                        name="rivalTeamPossession"
                        type="number"
                        onChange={(event) => {
                          setRivalPosession(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="rivalTeamTotalShots"
                        label="Rival Team's Total Shots"
                        name="rivalTeamTotalShots"
                        type="number"
                        onChange={(event) => {
                          setRivalTotalShots(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="rivalTeamChancesCreated"
                        label="Rival Team's Chances Created"
                        name="rivalTeamChancesCreated"
                        type="number"
                        onChange={(event) => {
                          setRivalChancesCreated(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="rivalTeamTotalAccuratePasses"
                        label="Rival Team's Total Accurate Passes"
                        name="rivalTeamTotalAccuratePasses"
                        type="number"
                        onChange={(event) => {
                          setRivalTotalAccuratePasses(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="rivalTeamPassSuccessRate"
                        label="Rival Team's Pass Success Rate"
                        name="rivalTeamPassSuccessRate"
                        type="number"
                        onChange={(event) => {
                          setRivalPassSuccessRate(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="rivalTeamFoulsConceded"
                        label="Rival Team Fouls Conceded"
                        name="rivalTeamFoulsConceded"
                        type="number"
                        onChange={(event) => {
                          setRivalFoulsConceded(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="rivalTeamCorners"
                        label="Rival Team's Corners"
                        name="rivalTeamCorners"
                        type="number"
                        onChange={(event) => {
                          setRivalCorners(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.itemPadding}
                        variant="outlined"
                        margin="normal"
                        id="rivalTeamOffsides"
                        label="Rival Team's Offsides"
                        name="RivalTeamOffsides"
                        type="number"
                        onChange={(event) => {
                          setRivalOffsides(event.target.value);
                        }}
                      />
                    </Grid>
                  </form>
                </Grid>
              </div>
              <Grid item xs={12}>
                <div className={classes.buttonAlign}>
                  <Button
                    className={classes.recordButton}
                    color={"primary"}
                    variant="outlined"
                    onClick={() => {
                      recordMatchStats();
                    }}
                  >
                    Record Match Stats
                  </Button>
                </div>
              </Grid>
            </Paper>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
};

export default MatchStats;
