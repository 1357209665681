export const cardOptions = [
  {
    value: "Yellow",
    label: "Yellow",
  },
  {
    value: "Red",
    label: "Red",
  },
];
