import React from "react";

const Training = () => {
  return (
    <React.Fragment>
      <h1>Training</h1>

      {/* 
      1. Training page must list all training sessions.
      2. Training page must have an add training session option.
      3. The add training page must include individual training or team based training drills - it must collect the date of training.
      4. Once the training is created it can be selected to add training details for individual performances.
      5. Once the training performance is saved, it should be accessible within the training similar to fixtures page.
      6. The training performance should relect in the my squad page under each player.
      7. Versions of the player attributes should be saved to the player profile.
      */}
    </React.Fragment>
  );
};

export default Training;
