import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./store/reducers/rootReducer";
import { AuthProvider } from "./contexts/AuthContext";
import SignUp from "./components/Authentication/SignUp/SignUp";
import LogIn from "./components/Authentication/LogIn/LogIn";
import ForgotPassword from "./components/Authentication/ForgotPassword/ForgotPassword";
import PrivateRoute from "./routes/PrivateRoute";

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        {/* <App></App> */}
        <Switch>
          <PrivateRoute exact={true} path="/" component={App}></PrivateRoute>
          <PrivateRoute
            exact={true}
            path="/squad"
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/rivals"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/fixtures"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/fixtures/add-fixture"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/fixtures/view-fixture/:id"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/fixtures/edit-fixture/:id"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/fixtures/match-details/:id"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/fixtures/match-stats/:id"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/tournaments"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/tournaments/add-tournament"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/tournaments/:id"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/training"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/settings"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/squad/view-player/:id"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/squad/edit-player/:id"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/squad/add-player"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/rivals/add-rival"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/rivals/add-player/:id"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/rivals/:id"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/rivals/:rivalid/view-player/:id"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            path="/rivals/:rivalid/edit-player/:id"
            exact={true}
            component={App}
          ></PrivateRoute>
          <PrivateRoute
            exact={true}
            path="/signup"
            component={SignUp}
          ></PrivateRoute>
          <Route exact={true} path="/login" component={LogIn}></Route>
          <Route
            exact={true}
            path="/forgot-password"
            component={ForgotPassword}
          ></Route>
          <PrivateRoute component={App}></PrivateRoute>
        </Switch>
      </AuthProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
reportWebVitals();
