import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  basicPadding: {
    padding: theme.spacing(1),
  },
  headerButtonContainer: {
    display: "flex",
  },
  buttonHeight: {
    height: "40px",
  },
  squadHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  textDecorRemoveButton: {
    textDecoration: "none",
    color: "white",
  },
  textDecorRemovePlayer: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
  },
  footballBoardWithPlayers: {
    position: "relative",
  },
  footballBoard: {
    position: "block",
    width: "100%",
  },
  substitute: {
    padding: theme.spacing(1),
    width: "20%",
    backgroundColor: "white",
    borderRadius: "5px",
  },
}));

// 442
export const formation442 = {
  playerOne: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "12%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerTwo: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "12%",
    borderRadius: "5px",
  },
  playerThree: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "33%",
    borderRadius: "5px",
  },
  playerFour: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "52%",
    borderRadius: "5px",
  },
  playerFive: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "73%",
    borderRadius: "5px",
  },
  playerSix: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "51%",
    left: "12%",
    borderRadius: "5px",
  },
  playerSeven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "51%",
    left: "33%",
    borderRadius: "5px",
  },
  playerEight: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "51%",
    left: "52%",
    borderRadius: "5px",
  },
  playerNine: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "51%",
    left: "73%",
    borderRadius: "5px",
  },
  playerTen: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "33%",
    borderRadius: "5px",
  },
  playerEleven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "52%",
    borderRadius: "5px",
  },
};

// 433
export const formation433 = {
  playerOne: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "12%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerTwo: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "12%",
    borderRadius: "5px",
  },
  playerThree: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "33%",
    borderRadius: "5px",
  },
  playerFour: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "52%",
    borderRadius: "5px",
  },
  playerFive: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "73%",
    borderRadius: "5px",
  },
  playerSix: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "51%",
    left: "20%",
    borderRadius: "5px",
  },
  playerSeven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "51%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerEight: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "51%",
    left: "65%",
    borderRadius: "5px",
  },
  playerNine: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "20%",
    borderRadius: "5px",
  },
  playerTen: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerEleven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "65%",
    borderRadius: "5px",
  },
};

// 4231
export const formation4231 = {
  playerOne: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "12%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerTwo: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "12%",
    borderRadius: "5px",
  },
  playerThree: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "33%",
    borderRadius: "5px",
  },
  playerFour: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "52%",
    borderRadius: "5px",
  },
  playerFive: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "73%",
    borderRadius: "5px",
  },
  playerSix: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "43.5%",
    left: "33%",
    borderRadius: "5px",
  },
  playerSeven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "43.5%",
    left: "52%",
    borderRadius: "5px",
  },
  playerEight: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "58%",
    left: "20%",
    borderRadius: "5px",
  },
  playerNine: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "58%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerTen: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "58%",
    left: "65%",
    borderRadius: "5px",
  },
  playerEleven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "42.5%",
    borderRadius: "5px",
  },
};

// 41212
export const formation41212 = {
  playerOne: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "12%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerTwo: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "12%",
    borderRadius: "5px",
  },
  playerThree: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "33%",
    borderRadius: "5px",
  },
  playerFour: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "52%",
    borderRadius: "5px",
  },
  playerFive: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "73%",
    borderRadius: "5px",
  },
  playerSix: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "43.5%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerSeven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "53%",
    left: "20%",
    borderRadius: "5px",
  },
  playerEight: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "53%",
    left: "65%",
    borderRadius: "5px",
  },
  playerNine: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "61%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerTen: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "33%",
    borderRadius: "5px",
  },
  playerEleven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "52%",
    borderRadius: "5px",
  },
};

// 4141
export const formation4141 = {
  playerOne: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "12%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerTwo: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "12%",
    borderRadius: "5px",
  },
  playerThree: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "33%",
    borderRadius: "5px",
  },
  playerFour: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "52%",
    borderRadius: "5px",
  },
  playerFive: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "73%",
    borderRadius: "5px",
  },
  playerSix: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "42%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerSeven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "53%",
    left: "12%",
    borderRadius: "5px",
  },
  playerEight: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "53%",
    left: "33%",
    borderRadius: "5px",
  },
  playerNine: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "53%",
    left: "52%",
    borderRadius: "5px",
  },
  playerTen: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "53%",
    left: "73%",
    borderRadius: "5px",
  },
  playerEleven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "42.5%",
    borderRadius: "5px",
  },
};
// 343
export const formation343 = {
  playerOne: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "12%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerTwo: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "20%",
    borderRadius: "5px",
  },
  playerThree: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerFour: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "65%",
    borderRadius: "5px",
  },
  playerFive: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "51%",
    left: "12%",
    borderRadius: "5px",
  },
  playerSix: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "51%",
    left: "33%",
    borderRadius: "5px",
  },
  playerSeven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "51%",
    left: "52%",
    borderRadius: "5px",
  },
  playerEight: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "51%",
    left: "73%",
    borderRadius: "5px",
  },
  playerNine: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "20%",
    borderRadius: "5px",
  },
  playerTen: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerEleven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "65%",
    borderRadius: "5px",
  },
};
// 352
export const formation352 = {
  playerOne: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "12%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerTwo: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "20%",
    borderRadius: "5px",
  },
  playerThree: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerFour: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "30%",
    left: "65%",
    borderRadius: "5px",
  },
  playerFive: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "42%",
    left: "42.5%",
    borderRadius: "5px",
  },
  playerSix: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "53%",
    left: "12%",
    borderRadius: "5px",
  },
  playerSeven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "53%",
    left: "33%",
    borderRadius: "5px",
  },
  playerEight: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "53%",
    left: "52%",
    borderRadius: "5px",
  },
  playerNine: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "53%",
    left: "73%",
    borderRadius: "5px",
  },
  playerTen: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "33%",
    borderRadius: "5px",
  },
  playerEleven: {
    position: "absolute",
    width: "15%",
    backgroundColor: "white",
    bottom: "73%",
    left: "52%",
    borderRadius: "5px",
  },
};
