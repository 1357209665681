import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Chart from "chart.js";
import firebase from "../../firebase/firebase";
import "firebase/firestore";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

// To Do List:
// 1. Loading Screen for all pages
// 2. Data not available for items that are empty
// 3. make the user list into an array

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  listItemStyles: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
  },
  dateStyles: {
    fontSize: "13px",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    paddingBottom: "15px",
  },
  squadAttributes: {
    paddingBottom: "25px",
  },
  squadChartHidden: {
    visibility: "hidden",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const [squad, setSquad] = useState([]);
  const [fixture, setFixture] = useState([]);
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [fixtureDataDisplayState, setFixtureDataDisplayState] = useState(false);
  const [squadDataDisplayState, setSquadDataDisplayState] = useState(false);

  let lsClubID = "123";

  let lsClubName = {
    clubName: "Loading...",
  };

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubName.clubName = parsedAuthState.clubName;
    lsClubID = parsedAuthState.clubID;
  }

  const clubName = useSelector((state) => {
    if (state.authReducer.clubName) {
      return state.authReducer.clubName;
    } else {
      return undefined;
    }
  });

  useEffect(() => {
    const fetchSquad = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Squad");

      docRef
        .get()
        .then((querySnapshot) => {
          const squadArray = [];
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            squadArray.push({ id: doc.id, playerDetails: doc.data() });
          });
          setSquad(squadArray);
          if (Object.keys(squadArray).length >= 1) {
            // console.log("length", Object.keys(squadArray).length);
            setSquadDataDisplayState(true);
          }
          setLoadingComponent(false);
        })
        .catch((error) => console.log(error));
    };
    fetchSquad();
    const fetchFixture = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Fixtures");

      docRef
        .get()
        .then((querySnapshot) => {
          const fixtureArray = [];
          querySnapshot.forEach((doc) => {
            // console.log(doc.id, " => ", doc.data());
            fixtureArray.push({ id: doc.id, fixtureDetails: doc.data() });
          });
          setFixture(fixtureArray);
          if (Object.keys(fixtureArray).length >= 1) {
            // console.log("length", Object.keys(fixtureArray).length);
            setFixtureDataDisplayState(true);
          }
          setLoadingComponent(false);
        })
        .catch((error) => console.log(error));
    };
    fetchFixture();
  }, [lsClubID]);

  let squadChart = React.createRef();

  useEffect(() => {
    const summedSquadDetails = {
      aerial: 0,
      attacking: 0,
      defending: 0,
      mental: 0,
      physical: 0,
      speed: 0,
      technical: 0,
      vision: 0,
    };

    squad.forEach((player) => {
      summedSquadDetails.aerial =
        summedSquadDetails.aerial + player.playerDetails.aerial;
      summedSquadDetails.attacking =
        summedSquadDetails.attacking + player.playerDetails.attacking;
      summedSquadDetails.defending =
        summedSquadDetails.defending + player.playerDetails.defending;
      summedSquadDetails.mental =
        summedSquadDetails.mental + player.playerDetails.mental;
      summedSquadDetails.physical =
        summedSquadDetails.physical + player.playerDetails.physical;
      summedSquadDetails.speed =
        summedSquadDetails.speed + player.playerDetails.speed;
      summedSquadDetails.technical =
        summedSquadDetails.technical + player.playerDetails.technical;
      summedSquadDetails.vision =
        summedSquadDetails.vision + player.playerDetails.vision;
    });

    const averagedSquadDetails = {
      aerial: summedSquadDetails.aerial / squad.length,
      attacking: summedSquadDetails.attacking / squad.length,
      defending: summedSquadDetails.defending / squad.length,
      mental: summedSquadDetails.mental / squad.length,
      physical: summedSquadDetails.physical / squad.length,
      speed: summedSquadDetails.speed / squad.length,
      technical: summedSquadDetails.technical / squad.length,
      vision: summedSquadDetails.vision / squad.length,
    };

    const ctx = squadChart.current.getContext("2d");

    new Chart(ctx, {
      type: "radar",
      data: {
        labels: [
          "Aerial",
          "Attacking",
          "Defending",
          "Mental",
          "Physical",
          "Speed",
          "Technical",
          "Vision",
        ],
        datasets: [
          {
            label: "Attributes",
            backgroundColor: "rgba(00, 255, 00, 0.1)",
            borderColor: "#00FF00",
            borderWidth: 2,
            data: [
              averagedSquadDetails.aerial,
              averagedSquadDetails.attacking,
              averagedSquadDetails.defending,
              averagedSquadDetails.mental,
              averagedSquadDetails.physical,
              averagedSquadDetails.speed,
              averagedSquadDetails.technical,
              averagedSquadDetails.vision,
            ],
          },
        ],
      },
      options: {
        maintainAspectRatio: true,
        scale: {
          ticks: {
            beginAtZero: true,
            max: 20,
          },
        },
      },
    });
  }, [squadChart, squad]);

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {!loadingComponent ? (
        <div>
          <h1>Dashboard</h1>
          <div className={classes.gridContainer}>
            <Grid item xs={5}>
              <Paper className={classes.paper}>
                <h3>My Fixtures</h3>
                {fixtureDataDisplayState ? (
                  <List>
                    {fixture.map((fixture) => {
                      let timestamp =
                        fixture.fixtureDetails.selectedDate.toDate();
                      let month = [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                      ];

                      let date = new Date(timestamp);
                      let fixtureMonth = month[date.getMonth()];

                      if (fixture.fixtureDetails.homeOrAway === "home") {
                        return (
                          <Link
                            key={fixture.id}
                            className={classes.listItemStyles}
                            to={"/fixtures/view-fixture/" + fixture.id}
                          >
                            <ListItem button>
                              <ListItemText>
                                <div className={classes.dateStyles}>
                                  {fixtureMonth.substring(0, 3)}{" "}
                                  {date.getDate()}{" "}
                                  {/* {fixture.fixtureDetails.selectedDate.toDate().substring(3, 14)} */}
                                </div>
                                {clubName ? clubName : lsClubName.clubName} vs{" "}
                                {fixture.fixtureDetails.rivalName}
                              </ListItemText>
                            </ListItem>
                          </Link>
                        );
                      } else {
                        return (
                          <Link
                            key={fixture.id}
                            className={classes.listItemStyles}
                            to={"/fixtures/view-fixture/" + fixture.id}
                          >
                            <ListItem button>
                              <ListItemText>
                                <div className={classes.dateStyles}>
                                  {fixtureMonth.substring(0, 3)}{" "}
                                  {date.getDate()}{" "}
                                </div>
                                {fixture.fixtureDetails.rivalName} vs{" "}
                                {clubName ? clubName : lsClubName.clubName}
                              </ListItemText>
                            </ListItem>
                          </Link>
                        );
                      }
                    })}
                  </List>
                ) : (
                  <ListItem>
                    {" "}
                    <ListItemText>No data added yet</ListItemText>
                  </ListItem>
                )}
              </Paper>
            </Grid>
            <Grid item xs={5}>
              <Paper className={classes.paper}>
                <h3>My Squad's Attributes</h3>
                <div className={classes.squadAttributes}>
                  <canvas id="squadRadarChart" ref={squadChart} />
                </div>
              </Paper>
            </Grid>
          </div>

          {/* display the a chart of the average player stats*/}

          {/* display the fixtures*/}
          <div className={classes.gridContainer}>
            <Grid item xs={5}>
              <Paper className={classes.paper}>
                <h3>My Squad</h3>
                {squadDataDisplayState ? (
                  <List>
                    {squad.map((player) => (
                      <Link
                        key={player.id}
                        className={classes.listItemStyles}
                        to={"/squad/view-player/" + player.id}
                      >
                        <ListItem button>
                          <ListItemText>
                            {player.playerDetails.firstName}{" "}
                            {player.playerDetails.lastName}
                          </ListItemText>
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                ) : (
                  <ListItem>
                    <ListItemText>No data added yet</ListItemText>
                  </ListItem>
                )}
              </Paper>
            </Grid>

            <Grid item xs={5}></Grid>
          </div>
        </div>
      ) : (
        <div className={classes.squadChartHidden}>
          <canvas id="squadRadarChart" ref={squadChart} />
        </div>
      )}
    </React.Fragment>
  );
};

export default Dashboard;
