import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Chart from "chart.js";
import validator from "validator";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import LinearProgress from "@material-ui/core/LinearProgress";

import firebase from "../../../../firebase/firebase";
import "firebase/firestore";
import { attributesOptions } from "../../../MySquad/utils/attributesOptions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  buttonHeight: {
    height: "40px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
  },
  headerButtonContainer: {
    display: "flex",
  },
  textField: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  selectField: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  basicPadding: {
    padding: theme.spacing(1),
  },
  textDecorRemoveButton: {
    textDecoration: "none",
    color: "white",
  },
  playerChartHidden: {
    visibility: "hidden",
  },
}));

const EditRivalPlayer = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [firstNameErrorState, setFirstNameErrorState] = useState(false);
  const [lastName, setLastName] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    "Document data: Thu Nov 22 1990 00:00:00 GMT-0500 (Eastern Standard Time)"
  );
  const [aerialOption, setAerialOption] = useState(1);
  const [attackingOption, setAttackingOption] = useState(1);
  const [defendingOption, setDefendingOption] = useState(1);
  const [mentalOption, setMentalOption] = useState(1);
  const [physicalOption, setPhysicalOption] = useState(1);
  const [speedOption, setSpeedOption] = useState(1);
  const [technicalOption, setTechnicalOption] = useState(1);
  const [visionOption, setVisionOption] = useState(1);

  const [playerDetails, setPlayerDetails] = useState({});

  let lsClubID = "Arsenal";

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubID = parsedAuthState.clubID;
  }

  useEffect(() => {
    const fetchData = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals")
        .doc(props.match.params.rivalid)
        .collection("Squad")
        .doc(props.match.params.id);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("Document data:", doc.data());
            setFirstName(doc.data().firstName);
            setLastName(doc.data().lastName);
            setSelectedDate(doc.data().dateOfBirth.toDate());
            setAerialOption(doc.data().aerial);
            setAttackingOption(doc.data().attacking);
            setDefendingOption(doc.data().defending);
            setMentalOption(doc.data().mental);
            setPhysicalOption(doc.data().physical);
            setSpeedOption(doc.data().speed);
            setTechnicalOption(doc.data().technical);
            setVisionOption(doc.data().vision);
            setLoadingComponent(false);
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    };
    fetchData();
  }, [props, lsClubID]);

  let playerChart = React.createRef();

  useEffect(() => {
    const ctx = playerChart.current.getContext("2d");

    new Chart(ctx, {
      type: "radar",
      data: {
        labels: [
          "Aerial",
          "Attacking",
          "Defending",
          "Mental",
          "Physical",
          "Speed",
          "Technical",
          "Vision",
        ],
        datasets: [
          {
            label: "Attributes",
            backgroundColor: "rgba(00, 255, 00, 0.1)",
            borderColor: "#00FF00",
            borderWidth: 2,
            data: [
              aerialOption,
              attackingOption,
              defendingOption,
              mentalOption,
              physicalOption,
              speedOption,
              technicalOption,
              visionOption,
            ],
          },
        ],
      },
      options: {
        maintainAspectRatio: true,
        scale: {
          ticks: {
            beginAtZero: true,
            max: 20,
          },
        },
      },
    });
  }, [
    aerialOption,
    attackingOption,
    defendingOption,
    mentalOption,
    physicalOption,
    speedOption,
    technicalOption,
    visionOption,
    playerChart,
  ]);

  useEffect(() => {
    setPlayerDetails({
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: selectedDate,
      aerial: aerialOption,
      attacking: attackingOption,
      defending: defendingOption,
      mental: mentalOption,
      physical: physicalOption,
      speed: speedOption,
      technical: technicalOption,
      vision: visionOption,
    });
  }, [
    firstName,
    lastName,
    selectedDate,
    aerialOption,
    attackingOption,
    defendingOption,
    mentalOption,
    physicalOption,
    speedOption,
    technicalOption,
    visionOption,
  ]);

  const handlePlayerModify = (e) => {
    e.preventDefault();
    console.log(playerDetails);
    if (validator.isEmpty(firstName) === true) {
      setFirstNameErrorState(true);
      return;
    }
    const createPlayerInFirestore = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals")
        .doc(props.match.params.rivalid)
        .collection("Squad")
        .doc(props.match.params.id);

      docRef
        .set(playerDetails)
        .then(() => {
          console.log("Document successfully updated!");
          history.push("/rivals/" + props.match.params.rivalid);
        })
        .catch((error) => console.log(error));
    };
    createPlayerInFirestore();
  };

  const handlePlayerDelete = (e) => {
    e.preventDefault();
    const deletePlayerFromFirestore = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals")
        .doc(props.match.params.rivalid)
        .collection("Squad")
        .doc(props.match.params.id);

      docRef
        .delete()
        .then(() => {
          console.log("Document successfully deleted!");
          history.push("/rivals/" + props.match.params.rivalid);
        })
        .catch((error) => console.log(error));
    };
    deletePlayerFromFirestore();
  };

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {!loadingComponent ? (
        <div>
          <div className={classes.headerContainer}>
            <h1>
              Edit Player: {firstName} {lastName}
            </h1>
            <div className={classes.headerButtonContainer}>
              <div className={classes.basicPadding}>
                <Link
                  className={classes.textDecorRemoveButton}
                  to={"/rivals/" + props.match.params.rivalid}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.buttonHeight}
                    onClick={handlePlayerModify}
                  >
                    Save Changes
                  </Button>
                </Link>
              </div>
              <div className={classes.basicPadding}>
                <Link
                  className={classes.textDecorRemoveButton}
                  to={"/rivals/" + props.match.params.rivalid}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={classes.buttonHeight}
                    onClick={handlePlayerDelete}
                  >
                    Delete player
                  </Button>
                </Link>
              </div>
              <div className={classes.basicPadding}>
                <Link
                  className={classes.textDecorRemoveButton}
                  to={"/rivals/" + props.match.params.rivalid}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonHeight}
                  >
                    Cancel
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <Grid item xs={5}>
              <Paper className={classes.paper}>
                <form className={classes.form} noValidate>
                  <TextField
                    className={classes.basicPadding}
                    variant="outlined"
                    margin="normal"
                    required
                    id="firstName"
                    label="First Name"
                    value={firstName}
                    name="lastName"
                    autoComplete="text"
                    error={firstNameErrorState}
                    autoFocus
                    onChange={(event) => {
                      setFirstName(event.target.value);
                    }}
                  />
                  <TextField
                    className={classes.basicPadding}
                    variant="outlined"
                    margin="normal"
                    id="lastName"
                    label="Last Name"
                    value={lastName}
                    name="lastName"
                    autoComplete="text"
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                  />
                  <br></br>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.basicPadding}
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-of-birth"
                      label="Date of Birth"
                      value={selectedDate}
                      onChange={setSelectedDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <TextField
                    className={classes.selectField}
                    id="select-aerial"
                    select
                    label="Aerial"
                    value={aerialOption}
                    onChange={(event) => {
                      setAerialOption(event.target.value);
                    }}
                  >
                    {attributesOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.selectField}
                    id="select-attacking"
                    select
                    label="Attacking"
                    value={attackingOption}
                    onChange={(event) => {
                      setAttackingOption(event.target.value);
                    }}
                  >
                    {attributesOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.selectField}
                    id="select-defending"
                    select
                    label="Defending"
                    value={defendingOption}
                    onChange={(event) => {
                      setDefendingOption(event.target.value);
                    }}
                  >
                    {attributesOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.selectField}
                    id="select-mental"
                    select
                    label="Mental"
                    value={mentalOption}
                    onChange={(event) => {
                      setMentalOption(event.target.value);
                    }}
                  >
                    {attributesOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.selectField}
                    id="select-physical"
                    select
                    label="Physical"
                    value={physicalOption}
                    onChange={(event) => {
                      setPhysicalOption(event.target.value);
                    }}
                  >
                    {attributesOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.selectField}
                    id="select-speed"
                    select
                    label="Speed"
                    value={speedOption}
                    onChange={(event) => {
                      setSpeedOption(event.target.value);
                    }}
                  >
                    {attributesOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.selectField}
                    id="select-technical"
                    select
                    label="Technical"
                    value={technicalOption}
                    onChange={(event) => {
                      setTechnicalOption(event.target.value);
                    }}
                  >
                    {attributesOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.selectField}
                    id="select-vision"
                    select
                    label="Vision"
                    value={visionOption}
                    onChange={(event) => {
                      setVisionOption(event.target.value);
                    }}
                  >
                    {attributesOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </form>
              </Paper>
            </Grid>
            <Grid item xs={5}>
              <Paper className={classes.paper}>
                <canvas id="playerRadarChart" ref={playerChart} />
              </Paper>
            </Grid>
          </div>
        </div>
      ) : (
        <div className={classes.playerChartHidden}>
          <canvas id="playerRadarChart" ref={playerChart} />
        </div>
      )}
    </React.Fragment>
  );
};

export default EditRivalPlayer;
