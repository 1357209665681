import firebase from "../../../../firebase/firebase";
import "firebase/firestore";

export const createClub = async (clubName, email) => {
  const db = firebase.firestore();
  let docRef = db.collection("organisation");

  docRef
    .add({ ClubName: clubName, ClubOwner: [email] })
    .then(() => {
      console.log("Club successfully created!");
    })
    .catch((error) => console.log(error));
};
