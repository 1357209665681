import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import EventIcon from "@material-ui/icons/Event";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
// import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";
import { NavLink } from "react-router-dom";

const textDecorRemove = {
  textDecoration: "none",
  color: "rgba(0, 0, 0, 0.87)",
};

export const NavbarListItems = (
  <div>
    <NavLink to="/" style={textDecorRemove}>
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </NavLink>

    <NavLink to="/squad" style={textDecorRemove}>
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="My Squad" />
      </ListItem>
    </NavLink>

    <NavLink to="/rivals" style={textDecorRemove}>
      <ListItem button>
        <ListItemIcon>
          <GpsFixedIcon />
        </ListItemIcon>
        <ListItemText primary="Rivals" />
      </ListItem>
    </NavLink>

    <NavLink to="/fixtures" style={textDecorRemove}>
      <ListItem button>
        <ListItemIcon>
          <EventIcon />
        </ListItemIcon>
        <ListItemText primary="Fixtures" />
      </ListItem>
    </NavLink>
    {/* <NavLink to="/tournaments" style={textDecorRemove}>
      <ListItem button>
        <ListItemIcon>
          <AssessmentIcon />
        </ListItemIcon>
        <ListItemText primary="Tournaments" />
      </ListItem>
    </NavLink> */}

    <NavLink to="/training" style={textDecorRemove}>
      <ListItem button>
        <ListItemIcon>
          <LocalLibraryIcon />
        </ListItemIcon>
        <ListItemText primary="Training" />
      </ListItem>
    </NavLink>
    <NavLink to="/settings" style={textDecorRemove}>
      <ListItem button>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Settings" />
      </ListItem>
    </NavLink>
  </div>
);
