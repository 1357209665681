import * as actionTypes from "./actionTypes";

export const loginAction = (clubID, email, clubName) => {
  return {
    type: actionTypes.LOGIN,
    clubID: clubID,
    userEmail: email,
    clubName: clubName,
  };
};
