import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import firebase from "../../../firebase/firebase";
import "firebase/firestore";
import footballBoard from "../../../assets/images/football-board.png";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import LinearProgress from "@material-ui/core/LinearProgress";
import { formationOptions } from "../../MySquad/utils/formationOptions";

import {
  useStyles,
  formation442,
  formation433,
  formation4231,
  formation41212,
  formation4141,
  formation343,
  formation352,
} from "./ViewRivalStyles";

const ViewRival = (props) => {
  const classes = useStyles();
  const [rivalName, setRivalName] = useState("");
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [squad, setSquad] = useState([]);
  const [formation, setFormation] = useState({
    value: "4 - 4 - 2",
    label: "4 - 4 - 2",
  });
  const [formationStyles, setFormationStyles] = useState({});
  const [playerOne, setPlayerOne] = useState("");
  const [playerTwo, setPlayerTwo] = useState("");
  const [playerThree, setPlayerThree] = useState("");
  const [playerFour, setPlayerFour] = useState("");
  const [playerFive, setPlayerFive] = useState("");
  const [playerSix, setPlayerSix] = useState("");
  const [playerSeven, setPlayerSeven] = useState("");
  const [playerEight, setPlayerEight] = useState("");
  const [playerNine, setPlayerNine] = useState("");
  const [playerTen, setPlayerTen] = useState("");
  const [playerEleven, setPlayerEleven] = useState("");

  const [substituteOne, setSubstituteOne] = useState("");
  const [substituteTwo, setSubstituteTwo] = useState("");
  const [substituteThree, setSubstituteThree] = useState("");
  const [substituteFour, setSubstituteFour] = useState("");
  const [substituteFive, setSubstituteFive] = useState("");
  const [substituteSix, setSubstituteSix] = useState("");
  const [substituteSeven, setSubstituteSeven] = useState("");

  const [saveChangesState, setSaveChangesState] = useState(false);
  const [rivalSquadDataDisplayState, setRivalSquadDataDisplayState] =
    useState(false);
  const [
    rivalPlayerSelectorDisabledState,
    setRivalPlayerSelectorDisabledState,
  ] = useState(false);

  let lsClubID = "Arsenal";

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubID = parsedAuthState.clubID;
  }

  useEffect(() => {
    const fetchRivalClubName = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals")
        .doc(props.match.params.id);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("Data from firestore", doc.data());
            setRivalName(doc.data().rivalName);
            setLoadingComponent(false);
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    };

    const fetchSquad = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals")
        .doc(props.match.params.id)
        .collection("Squad");

      docRef
        .get()
        .then((querySnapshot) => {
          const squadArray = [];
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            squadArray.push({ id: doc.id, playerDetails: doc.data() });
          });
          setSquad(squadArray);
          if (Object.keys(squadArray).length >= 1) {
            // console.log("length", Object.keys(squadArray).length);
            setRivalSquadDataDisplayState(true);
          } else {
            setRivalPlayerSelectorDisabledState(true);
          }
          setLoadingComponent(false);
        })
        .catch((error) => console.log(error));
    };

    const fetchFormation = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals")
        .doc(props.match.params.id)
        .collection("FootballBoard")
        .doc("Formation");

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("Data from firestore", doc.data());
            setFormation(doc.data());
            setLoadingComponent(false);
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    };

    const fetchPlayers = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals")
        .doc(props.match.params.id)
        .collection("FootballBoard")
        .doc("Players");

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("Data from firestore", doc.data());
            setPlayerOne(doc.data().playerOne);
            setPlayerTwo(doc.data().playerTwo);
            setPlayerThree(doc.data().playerThree);
            setPlayerFour(doc.data().playerFour);
            setPlayerFive(doc.data().playerFive);
            setPlayerSix(doc.data().playerSix);
            setPlayerSeven(doc.data().playerSeven);
            setPlayerEight(doc.data().playerEight);
            setPlayerNine(doc.data().playerNine);
            setPlayerTen(doc.data().playerTen);
            setPlayerEleven(doc.data().playerEleven);
            setLoadingComponent(false);
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    };

    const fetchSubstitutes = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals")
        .doc(props.match.params.id)
        .collection("FootballBoard")
        .doc("Substitutes");

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("Data from firestore", doc.data());
            setSubstituteOne(doc.data().substituteOne);
            setSubstituteTwo(doc.data().substituteTwo);
            setSubstituteThree(doc.data().substituteThree);
            setSubstituteFour(doc.data().substituteFour);
            setSubstituteFive(doc.data().substituteFive);
            setSubstituteSix(doc.data().substituteSix);
            setSubstituteSeven(doc.data().substituteSeven);
            setLoadingComponent(false);
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    };
    fetchRivalClubName();
    fetchSquad();
    fetchFormation();
    fetchPlayers();
    fetchSubstitutes();
  }, [props.match.params.id, lsClubID]);

  // squad.forEach((player) => console.log(player.firstName));
  // console.log("useState =", squad);

  const handleSavingChanges = () => {
    const updateFormation = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals")
        .doc(props.match.params.id)
        .collection("FootballBoard")
        .doc("Formation");

      docRef
        .set(formation)
        .then(() => {
          // console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    };

    const updatePlayers = async () => {
      const players = {
        playerOne,
        playerTwo,
        playerThree,
        playerFour,
        playerFive,
        playerSix,
        playerSeven,
        playerEight,
        playerNine,
        playerTen,
        playerEleven,
      };
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals")
        .doc(props.match.params.id)
        .collection("FootballBoard")
        .doc("Players");

      docRef
        .set(players)
        .then(() => {
          // console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    };

    const updateSubstitute = async () => {
      const substitutes = {
        substituteOne,
        substituteTwo,
        substituteThree,
        substituteFour,
        substituteFive,
        substituteSix,
        substituteSeven,
      };
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals")
        .doc(props.match.params.id)
        .collection("FootballBoard")
        .doc("Substitutes");

      docRef
        .set(substitutes)
        .then(() => {
          // console.log("Document successfully written!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    };

    updateFormation();
    updatePlayers();
    updateSubstitute();
    setSaveChangesState(false);
  };

  useEffect(() => {
    let formationStyles;
    switch (formation.value) {
      case "4 - 4 - 2":
        formationStyles = formation442;
        setFormationStyles(formationStyles);
        break;
      case "4 - 3 - 3":
        formationStyles = formation433;
        setFormationStyles(formationStyles);
        break;
      case "4 - 2 - 3 - 1":
        formationStyles = formation4231;
        setFormationStyles(formationStyles);
        break;
      case "4 - 1 - 2 - 1 - 2":
        formationStyles = formation41212;
        setFormationStyles(formationStyles);
        break;
      case "4 - 1 - 4 - 1":
        formationStyles = formation4141;
        setFormationStyles(formationStyles);
        break;
      case "3 - 4 - 3":
        formationStyles = formation343;
        setFormationStyles(formationStyles);
        break;
      case "3 - 5 - 2":
        formationStyles = formation352;
        setFormationStyles(formationStyles);
        break;
      default:
        console.log("unhandled selection");
    }
  }, [formation]);

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {!loadingComponent && (
        <div>
          <div className={classes.squadHeaderContainer}>
            <h1>
              Rival Squad: {rivalName}{" "}
              <Link
                className={classes.textDecorRemoveButton}
                to={"/rivals/edit-rival/" + props.match.params.id}
              >
                <IconButton color="primary" className={classes.buttonHeight}>
                  <EditOutlinedIcon />
                </IconButton>
              </Link>
            </h1>
            <div className={classes.headerButtonContainer}>
              <div className={classes.basicPadding}>
                {saveChangesState && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonHeight}
                    onClick={handleSavingChanges}
                  >
                    Save Changes
                  </Button>
                )}
              </div>
              <div className={classes.basicPadding}>
                <Link
                  className={classes.textDecorRemoveButton}
                  to={"/rivals/add-player/" + props.match.params.id}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.buttonHeight}
                  >
                    Add Player
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <Grid item xs={4}>
              <Paper className={classes.paper}>
                {rivalSquadDataDisplayState ? (
                  <List>
                    {squad.map((player) => (
                      <Link
                        key={player.id}
                        className={classes.textDecorRemovePlayer}
                        to={
                          "/rivals/" +
                          props.match.params.id +
                          "/view-player/" +
                          player.id
                        }
                      >
                        <ListItem button>
                          <ListItemText>
                            {player.playerDetails.firstName}{" "}
                            {player.playerDetails.lastName}
                          </ListItemText>
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                ) : (
                  <ListItem>
                    <ListItemText>No data added yet</ListItemText>
                  </ListItem>
                )}
              </Paper>
            </Grid>
            <Grid item xs={7}>
              <Paper className={classes.paper}>
                <TextField
                  className={classes.selectField}
                  id="select-formation"
                  select
                  label="Formation"
                  value={formation.label}
                  onChange={(event) => {
                    setFormation({
                      value: event.target.value,
                      label: event.target.value,
                    });
                    setSaveChangesState(true);
                  }}
                >
                  {formationOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br></br>
                <div className={classes.footballBoardWithPlayers}>
                  <img
                    src={footballBoard}
                    alt="footballBoard"
                    className={classes.footballBoard}
                  />
                  <TextField
                    // className={classes.playerOne433}
                    style={formationStyles.playerOne}
                    id="select-player-one"
                    select
                    variant="outlined"
                    value={playerOne}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setPlayerOne(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    // className={classes.playerTwo433}
                    style={formationStyles.playerTwo}
                    id="select-player-two"
                    select
                    variant="outlined"
                    value={playerTwo}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setPlayerTwo(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    // className={classes.playerThree433}
                    style={formationStyles.playerThree}
                    id="select-player-three"
                    select
                    variant="outlined"
                    value={playerThree}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setPlayerThree(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    // className={classes.playerFour433}
                    style={formationStyles.playerFour}
                    id="select-player-four"
                    select
                    variant="outlined"
                    value={playerFour}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setPlayerFour(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    // className={classes.playerFive433}
                    style={formationStyles.playerFive}
                    id="select-player-five"
                    select
                    variant="outlined"
                    value={playerFive}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setPlayerFive(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    // className={classes.playerSix433}
                    style={formationStyles.playerSix}
                    id="select-player-six"
                    select
                    variant="outlined"
                    value={playerSix}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setPlayerSix(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    // className={classes.playerSeven433}
                    style={formationStyles.playerSeven}
                    id="select-player-seven"
                    select
                    variant="outlined"
                    value={playerSeven}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setPlayerSeven(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    // className={classes.playerEight433}
                    style={formationStyles.playerEight}
                    id="select-player-eight"
                    select
                    variant="outlined"
                    value={playerEight}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setPlayerEight(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    // className={classes.playerNine433}
                    style={formationStyles.playerNine}
                    id="select-player-nine"
                    select
                    variant="outlined"
                    value={playerNine}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setPlayerNine(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    // className={classes.playerTen433}
                    style={formationStyles.playerTen}
                    id="select-player-ten"
                    select
                    variant="outlined"
                    value={playerTen}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setPlayerTen(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    // className={classes.playerEleven433}
                    style={formationStyles.playerEleven}
                    id="select-player-eleven"
                    select
                    variant="outlined"
                    value={playerEleven}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setPlayerEleven(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <h5>Substitutes:</h5>
                <div>
                  <TextField
                    className={classes.substitute}
                    id="select-sub-one"
                    select
                    variant="outlined"
                    value={substituteOne}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setSubstituteOne(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.substitute}
                    id="select-sub-two"
                    select
                    variant="outlined"
                    value={substituteTwo}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setSubstituteTwo(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.substitute}
                    id="select-sub-three"
                    select
                    variant="outlined"
                    value={substituteThree}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setSubstituteThree(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.substitute}
                    id="select-sub-four"
                    select
                    variant="outlined"
                    value={substituteFour}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setSubstituteFour(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.substitute}
                    id="select-sub-five"
                    select
                    variant="outlined"
                    value={substituteFive}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setSubstituteFive(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.substitute}
                    id="select-sub-six"
                    select
                    variant="outlined"
                    value={substituteSix}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setSubstituteSix(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    className={classes.substitute}
                    id="select-sub-seven"
                    select
                    variant="outlined"
                    value={substituteSeven}
                    disabled={rivalPlayerSelectorDisabledState}
                    onChange={(event) => {
                      setSubstituteSeven(event.target.value);
                      setSaveChangesState(true);
                    }}
                  >
                    {squad.map((player) => (
                      <MenuItem
                        key={player.id}
                        value={player.playerDetails.firstName}
                      >
                        {player.playerDetails.firstName}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </Paper>
            </Grid>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ViewRival;
