import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  removeTextDecor: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
}));

const Settings = () => {
  const classes = useStyles();
  const [loadingComponent, setLoadingComponent] = useState(true);

  let lsClubDetails = {
    clubName: "Loading...",
    lsClubID: "Arsenal",
    userEmail: "prithvirajkumar14@gmail.com",
  };

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubDetails.clubName = parsedAuthState.clubName;
    lsClubDetails.lsClubID = parsedAuthState.clubID;
    lsClubDetails.userEmail = parsedAuthState.userEmail;
  }

  const clubDetails = useSelector((state) => {
    if (state.authReducer.clubName) {
      return {
        clubName: state.authReducer.clubName,
        clubID: state.authReducer.clubID,
        userEmail: state.authReducer.userEmail,
      };
    } else {
      return undefined;
    }
  });

  useEffect(() => {
    setLoadingComponent(false);
  }, []);

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {!loadingComponent && (
        <div>
          <h1>Settings</h1>
          <div className={classes.gridContainer}>
            <Grid item xs={5}>
              <Paper className={classes.paper}>
                <List aria-label="settings">
                  <Link
                    className={classes.removeTextDecor}
                    to={"/settings/view-club-details/"}
                  >
                    <ListItem button>
                      <ListItemText>
                        {"Club Details: "}
                        {clubDetails
                          ? clubDetails.clubName
                          : lsClubDetails.clubName}
                      </ListItemText>
                    </ListItem>
                  </Link>
                  <ListItem>
                    <ListItemText>
                      {"User Details: "}
                      {clubDetails
                        ? clubDetails.userEmail
                        : lsClubDetails.userEmail}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      {"Subscription Details: Starter Plan"}
                    </ListItemText>
                  </ListItem>
                </List>
              </Paper>
            </Grid>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Settings;
