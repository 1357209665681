import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import Chart from "chart.js";
import validator from "validator";

import firebase from "../../../firebase/firebase";
import "firebase/firestore";
import { attributesOptions } from "../utils/attributesOptions";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  buttonHeight: {
    height: "40px",
  },
  squadHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
  },
  textField: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  selectField: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  basicPadding: {
    padding: theme.spacing(1),
  },
  textDecorRemoveButton: {
    textDecoration: "none",
    color: "white",
  },
}));

const AddPlayer = () => {
  const classes = useStyles();
  const history = useHistory();

  const [firstName, setFirstName] = useState("");
  const [firstNameErrorState, setFirstNameErrorState] = useState(false);
  const [lastName, setLastName] = useState("");
  const [aerialOption, setAerialOption] = useState(1);
  const [attackingOption, setAttackingOption] = useState(1);
  const [defendingOption, setDefendingOption] = useState(1);
  const [mentalOption, setMentalOption] = useState(1);
  const [physicalOption, setPhysicalOption] = useState(1);
  const [speedOption, setSpeedOption] = useState(1);
  const [technicalOption, setTechnicalOption] = useState(1);
  const [visionOption, setVisionOption] = useState(1);

  const [playerDetails, setPlayerDetails] = useState({});

  const [selectedDate, setSelectedDate] = useState(new Date());

  let playerChart = React.createRef();

  let lsClubID = "Arsenal";

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubID = parsedAuthState.clubID;
  }

  useEffect(() => {
    const ctx = playerChart.current.getContext("2d");

    new Chart(ctx, {
      type: "radar",
      data: {
        labels: [
          "Aerial",
          "Attacking",
          "Defending",
          "Mental",
          "Physical",
          "Speed",
          "Technical",
          "Vision",
        ],
        datasets: [
          {
            label: "Attributes",
            backgroundColor: "rgba(00, 255, 00, 0.1)",
            borderColor: "#00FF00",
            borderWidth: 2,
            data: [
              aerialOption,
              attackingOption,
              defendingOption,
              mentalOption,
              physicalOption,
              speedOption,
              technicalOption,
              visionOption,
            ],
          },
        ],
      },
      options: {
        maintainAspectRatio: true,
        scale: {
          ticks: {
            beginAtZero: true,
            max: 20,
          },
        },
      },
    });
  }, [
    aerialOption,
    attackingOption,
    defendingOption,
    mentalOption,
    physicalOption,
    speedOption,
    technicalOption,
    visionOption,
    playerChart,
  ]);

  useEffect(() => {
    setPlayerDetails({
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: selectedDate,
      aerial: aerialOption,
      attacking: attackingOption,
      defending: defendingOption,
      mental: mentalOption,
      physical: physicalOption,
      speed: speedOption,
      technical: technicalOption,
      vision: visionOption,
    });
  }, [
    firstName,
    lastName,
    selectedDate,
    aerialOption,
    attackingOption,
    defendingOption,
    mentalOption,
    physicalOption,
    speedOption,
    technicalOption,
    visionOption,
  ]);

  const handlePlayerCreate = (e) => {
    e.preventDefault();
    console.log(playerDetails);
    if (validator.isEmpty(firstName) === true) {
      setFirstNameErrorState(true);
      return;
    }

    const createPlayerInFirestore = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Squad");

      docRef
        .add(playerDetails)
        .then(() => {
          console.log("Document successfully written!");
          history.push("/squad");
        })
        .catch((error) => console.log(error));
    };
    createPlayerInFirestore();
  };

  return (
    <React.Fragment>
      <div className={classes.squadHeaderContainer}>
        <h1>Add Player</h1>
        <Link className={classes.textDecorRemoveButton} to="/squad">
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonHeight}
            onClick={handlePlayerCreate}
          >
            Create Player
          </Button>
        </Link>
      </div>
      <div className={classes.gridContainer}>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <form className={classes.form} noValidate>
              <TextField
                className={classes.basicPadding}
                variant="outlined"
                margin="normal"
                required
                id="firstName"
                label="First Name"
                name="lastName"
                autoComplete="text"
                error={firstNameErrorState}
                autoFocus
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
              <TextField
                className={classes.basicPadding}
                variant="outlined"
                margin="normal"
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="text"
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
              <br></br>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.basicPadding}
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-of-birth"
                  label="Date of Birth"
                  value={selectedDate}
                  onChange={setSelectedDate}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
              <TextField
                className={classes.selectField}
                id="select-aerial"
                select
                label="Aerial"
                value={aerialOption}
                onChange={(event) => {
                  setAerialOption(event.target.value);
                }}
              >
                {attributesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.selectField}
                id="select-attacking"
                select
                label="Attacking"
                value={attackingOption}
                onChange={(event) => {
                  setAttackingOption(event.target.value);
                }}
              >
                {attributesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.selectField}
                id="select-defending"
                select
                label="Defending"
                value={defendingOption}
                onChange={(event) => {
                  setDefendingOption(event.target.value);
                }}
              >
                {attributesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.selectField}
                id="select-mental"
                select
                label="Mental"
                value={mentalOption}
                onChange={(event) => {
                  setMentalOption(event.target.value);
                }}
              >
                {attributesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.selectField}
                id="select-physical"
                select
                label="Physical"
                value={physicalOption}
                onChange={(event) => {
                  setPhysicalOption(event.target.value);
                }}
              >
                {attributesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.selectField}
                id="select-speed"
                select
                label="Speed"
                value={speedOption}
                onChange={(event) => {
                  setSpeedOption(event.target.value);
                }}
              >
                {attributesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.selectField}
                id="select-technical"
                select
                label="Technical"
                value={technicalOption}
                onChange={(event) => {
                  setTechnicalOption(event.target.value);
                }}
              >
                {attributesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                className={classes.selectField}
                id="select-vision"
                select
                label="Vision"
                value={visionOption}
                onChange={(event) => {
                  setVisionOption(event.target.value);
                }}
              >
                {attributesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </form>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <canvas id="playerRadarChart" ref={playerChart} />
          </Paper>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default AddPlayer;
