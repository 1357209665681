import React, { useState } from "react";
import validator from "validator";

import Alert from "@material-ui/lab/Alert";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useAuth } from "../../../contexts/AuthContext";
import { useHistory } from "react-router";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://scoboard.com/">
        scoboard.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LogIn() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [emailLabel, setEmailLabel] = useState("Enter Email Address");
  const [emailErrorState, setEmailErrorState] = useState(false);
  const [notification, setNotification] = useState("");
  const { resetPassword } = useAuth();
  const history = useHistory();

  const passwordResetHandler = async (e) => {
    e.preventDefault();

    if (validator.isEmail(email) === false) {
      setEmailErrorState(true);
      setEmailLabel("Enter valid Email Address");
      return;
    }

    try {
      setNotification("");
      await resetPassword(email);
      setNotification(
        "If you have an account with us, you will be sent an email with further instructions"
      );
      setTimeout(() => {
        history.push("/login");
      }, 5000);
    } catch {
      setNotification(
        "If you have an account with us, you will be sent an email with further instructions"
      );
      setTimeout(() => {
        history.push("/login");
      }, 5000);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        {notification && <Alert severity="info">{notification}</Alert>}
        <form
          className={classes.form}
          noValidate
          onSubmit={passwordResetHandler}
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={emailLabel}
            error={emailErrorState}
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset Password
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/login" variant="body2">
                Back to Login page
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
