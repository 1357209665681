import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import validator from "validator";

import firebase from "../../../firebase/firebase";
import "firebase/firestore";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  buttonHeight: {
    height: "40px",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
  },
  headerButtonContainer: {
    display: "flex",
  },
  basicPadding: {
    padding: theme.spacing(1),
  },
  textDecorRemoveButton: {
    textDecoration: "none",
    color: "white",
  },
  formWidth: {
    width: "100%",
    padding: theme.spacing(1),
  },
  alertError: {
    marginBottom: "10px",
  },
}));

const AddFixture = () => {
  const classes = useStyles();
  const history = useHistory();
  const [rival, setRival] = useState([]);
  const [rivalName, setRivalName] = useState("");
  const [rivalNameErrorState, setRivalNameErrorState] = useState(false);
  const [rivalId, setRivalId] = useState("");
  const [location, setLocation] = useState("");
  const [tournament, setTournament] = useState("");
  const [refereeName, setRefereeName] = useState("");
  const [loadingComponent, setLoadingComponent] = useState(true);
  const [rivalLengthAlert, setRivalLengthAlert] = useState(false);
  const [rivalDisabledState, setRivalDisabledState] = useState(false);

  const [selectedDate, setSelectedDate] = useState(
    new Date("2020-01-01T12:00:00")
  );

  const [selectedTime, setSelectedTime] = useState(
    new Date("2020-01-01T12:00:00")
  );

  const [homeOrAway, setHomeOrAway] = useState("home");

  const [fixtureDetails, setFixtureDetails] = useState({});

  let lsClubID = "Arsenal";

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubID = parsedAuthState.clubID;
  }

  useEffect(() => {
    const fetchRivals = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Rivals");

      docRef
        .get()
        .then((querySnapshot) => {
          const rivalArray = [];
          querySnapshot.forEach((doc) => {
            rivalArray.push({ id: doc.id, rivalDetails: doc.data() });
          });
          setRival(rivalArray);
          if (Object.keys(rivalArray).length === 0) {
            console.log("length", Object.keys(rivalArray).length);
            setRivalLengthAlert(true);
            setRivalDisabledState(true);
          }
          setLoadingComponent(false);
        })
        .catch((error) => console.log(error));
    };
    fetchRivals();
  }, [lsClubID]);

  useEffect(() => {
    setFixtureDetails({
      rivalName: rivalName,
      rivalId: rivalId,
      homeOrAway: homeOrAway ? homeOrAway : "neutral",
      location: location,
      tournament: tournament,
      refereeName: refereeName,
      selectedDate: selectedDate,
      selectedTime: selectedTime,
      matchStatus: "notFinished",
    });
  }, [
    rivalName,
    rivalId,
    selectedDate,
    selectedTime,
    homeOrAway,
    location,
    tournament,
    refereeName,
  ]);

  useEffect(() => {
    setSelectedTime(selectedDate);
  }, [selectedDate]);

  const handleFixtureCreate = (e) => {
    e.preventDefault();
    if (validator.isEmpty(rivalId) === true) {
      setRivalNameErrorState(true);
      return;
    }
    // console.log(fixtureDetails);
    const createFixtureInFirestore = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Fixtures");

      docRef
        .add(fixtureDetails)
        .then(() => {
          console.log("Document successfully written!");
          history.push("/fixtures");
        })
        .catch((error) => console.log(error));
    };
    createFixtureInFirestore();
  };

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {!loadingComponent && (
        <div>
          <div>
            <div className={classes.headerContainer}>
              <h1>Add Fixture</h1>
              <div className={classes.headerButtonContainer}>
                <div className={classes.basicPadding}>
                  <Link
                    className={classes.textDecorRemoveButton}
                    to="/fixtures"
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.buttonHeight}
                      onClick={handleFixtureCreate}
                    >
                      Add Fixture
                    </Button>
                  </Link>
                </div>
                <div className={classes.basicPadding}>
                  <Link
                    className={classes.textDecorRemoveButton}
                    to="/fixtures"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonHeight}
                    >
                      Cancel
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            {rivalLengthAlert && (
              <Alert className={classes.alertError} severity="error">
                You must add a rival to create a fixture
              </Alert>
            )}
            <div className={classes.gridContainer}>
              <Grid item xs={5}>
                <Paper className={classes.paper}>
                  <form className={classes.form} noValidate>
                    <TextField
                      className={classes.formWidth}
                      id="rivalName"
                      label="Rival Name"
                      select
                      variant="outlined"
                      required
                      disabled={rivalDisabledState}
                      value={rivalName}
                      error={rivalNameErrorState}
                      onChange={(event) => {
                        setRivalName(event.target.value);
                        rival.forEach((rival) => {
                          if (
                            event.target.value === rival.rivalDetails.rivalName
                          ) {
                            setRivalId(rival.id);
                          }
                        });
                      }}
                    >
                      {rival.map((rival) => (
                        <MenuItem
                          key={rival.id}
                          value={rival.rivalDetails.rivalName}
                        >
                          {rival.rivalDetails.rivalName}
                        </MenuItem>
                      ))}
                    </TextField>

                    <ToggleButtonGroup
                      className={classes.formWidth}
                      value={homeOrAway}
                      exclusive
                      onChange={(event, newHomeOrAway) => {
                        setHomeOrAway(newHomeOrAway);
                      }}
                      aria-label="home or away"
                    >
                      <ToggleButton value="home" aria-label="home">
                        Home
                      </ToggleButton>
                      <ToggleButton value="away" aria-label="away">
                        Away
                      </ToggleButton>
                    </ToggleButtonGroup>

                    <TextField
                      className={classes.formWidth}
                      variant="outlined"
                      margin="normal"
                      id="location"
                      label="Location"
                      name="location"
                      autoComplete="text"
                      onChange={(event) => {
                        setLocation(event.target.value);
                      }}
                    />

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.formWidth}
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="fixture-date"
                        label="Fixture Date"
                        value={selectedDate}
                        onChange={(date) => {
                          setSelectedDate(date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        className={classes.formWidth}
                        margin="normal"
                        id="fixture-time"
                        label="Fixture Time"
                        value={selectedTime}
                        onChange={(date) => {
                          setSelectedTime(date);
                        }}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>

                    <TextField
                      className={classes.formWidth}
                      variant="outlined"
                      margin="normal"
                      id="tournament"
                      label="Tournament Name"
                      name="tournament"
                      autoComplete="text"
                      onChange={(event) => {
                        setTournament(event.target.value);
                      }}
                    />

                    <TextField
                      className={classes.formWidth}
                      variant="outlined"
                      margin="normal"
                      id="refereeName"
                      label="Referee Name"
                      name="refereeName"
                      autoComplete="text"
                      onChange={(event) => {
                        setRefereeName(event.target.value);
                      }}
                    />
                  </form>
                </Paper>
              </Grid>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AddFixture;
