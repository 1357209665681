import React from "react";
import clsx from "clsx";
import { Switch, Route, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
// import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
// import MenuIcon from "@material-ui/icons/Menu";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

import scoboardLogo from "../../assets/images/scoboard-logo.png";
import MySquad from "../MySquad/MySquad";
// import Tournaments from "../Tournaments/Tournaments";
import Dashboard from "../Dashboard/Dashboard";
import Rivals from "../Rivals/Rivals";
import Training from "../Training/Training";
import Settings from "../Settings/Settings";
import ViewPlayer from "../MySquad/ViewPlayer/ViewPlayer";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import { NavbarListItems } from "./navbarListItems/navbarListItems";
import AddPlayer from "../MySquad/AddPlayer/AddPlayer";
import EditPlayer from "../MySquad/EditPlayer/EditPlayer";
import AddRival from "../Rivals/AddRival/AddRival";
import EditRival from "../Rivals/EditRival/EditRival";
import ViewRival from "../Rivals/ViewRival/ViewRival";
import AddRivalPlayer from "../Rivals/ViewRival/AddRivalPlayer/AddRivalPlayer";
import ViewRivalPlayer from "../Rivals/ViewRival/ViewRivalPlayer/ViewRivalPlayer";
import EditRivalPlayer from "../Rivals/ViewRival/EditRivalPlayer/EditRivalPlayer";
// import AddTournament from "../Tournaments/AddTournament/AddTournament";
// import ViewTournament from "../Tournaments/ViewTournament/ViewTournament";
import Fixtures from "../Fixtures/Fixtures";
import AddFixture from "../Fixtures/AddFixture/AddFixture";
import ViewFixture from "../Fixtures/ViewFixture/ViewFixture";
import EditFixture from "../Fixtures/ViewFixture/EditFixture/EditFixture";
import MatchDetails from "../Fixtures/ViewFixture/MatchDetails/MatchDetails";
import MatchStats from "../Fixtures/ViewFixture/MatchStats/MatchStats";
import { useAuth } from "../../contexts/AuthContext";
import { useSelector } from "react-redux";
import ClubDetails from "../Settings/ClubDetails/ClubDetails";
import EditClubDetails from "../Settings/ClubDetails/EditClubDetails/EditClubDetails";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://scoboard.com/">
        scoboard.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "grid",
    gridTemplateColumns: "80% 20%",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  logout: {
    justifyContent: "flex-end",
    paddingRight: "1%",
    cursor: "pointer",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  chevronAlign: {
    display: "grid",
    gridTemplateColumns: "80% 20%",
  },
  scoboardAlign: {
    textAlign: "center",
    alignSelf: "center",
    paddingLeft: "15%",
    width: "90%",
  },
}));

export default function Layout() {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(true);
  const [open] = React.useState(true);
  const [error, setError] = React.useState("");
  const history = useHistory();
  const { logout } = useAuth();

  let lsClubName = {
    clubName: "Loading...",
  };

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubName.clubName = parsedAuthState.clubName;
  }

  const clubName = useSelector((state) => {
    // console.log(state);
    if (state.authReducer.clubName) {
      return state.authReducer.clubName;
    } else {
      return undefined;
    }
  });

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };
  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const handleLogout = async () => {
    setError("");
    try {
      await logout();
      localStorage.clear();
      history.push("/login");
    } catch {
      setError("Failed to Logout");
      console.log(error);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          {/* <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {clubName ? clubName : lsClubName.clubName}
          </Typography>
          <Typography
            component="h3"
            color="inherit"
            noWrap
            className={classes.logout}
            onClick={handleLogout}
          >
            Logout
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          {/* <h3 className={classes.scoboardAlign}>Scoboard</h3> */}
          <img
            src={scoboardLogo}
            alt="scoboardLogo"
            className={classes.scoboardAlign}
          />
          {/* <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton> */}
        </div>
        <Divider />
        <List>{NavbarListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Switch>
            <Route path="/" exact={true} component={Dashboard}></Route>
            <Route path="/squad" exact={true} component={MySquad}></Route>
            <Route path="/rivals" exact={true} component={Rivals}></Route>
            <Route path="/fixtures" exact={true} component={Fixtures}></Route>
            <Route
              path="/fixtures/add-fixture"
              exact={true}
              component={AddFixture}
            ></Route>
            <Route
              path="/fixtures/view-fixture/:id"
              exact={true}
              component={ViewFixture}
            ></Route>
            <Route
              path="/fixtures/edit-fixture/:id"
              exact={true}
              component={EditFixture}
            ></Route>
            <Route
              path="/fixtures/match-details/:id"
              exact={true}
              component={MatchDetails}
            ></Route>
            <Route
              path="/fixtures/match-stats/:id"
              exact={true}
              component={MatchStats}
            ></Route>
            {/* <Route
              path="/tournaments"
              exact={true}
              component={Tournaments}
            ></Route>
            <Route
              path="/tournaments/add-tournament"
              exact={true}
              component={AddTournament}
            ></Route>
            <Route
              path="/tournaments/:id"
              exact={true}
              component={ViewTournament}
            ></Route> */}
            <Route path="/training" exact={true} component={Training}></Route>
            <Route path="/settings" exact={true} component={Settings}></Route>
            <Route
              path="/settings/view-club-details"
              exact={true}
              component={ClubDetails}
            ></Route>
            <Route
              path="/settings/edit-club-details"
              exact={true}
              component={EditClubDetails}
            ></Route>
            <Route
              path="/squad/view-player/:id"
              exact={true}
              component={ViewPlayer}
            ></Route>
            <Route
              path="/squad/edit-player/:id"
              exact={true}
              component={EditPlayer}
            ></Route>
            <Route
              path="/squad/add-player"
              exact={true}
              component={AddPlayer}
            ></Route>
            <Route
              path="/rivals/add-rival"
              exact={true}
              component={AddRival}
            ></Route>
            <Route
              path="/rivals/add-player/:id"
              exact={true}
              component={AddRivalPlayer}
            ></Route>
            <Route
              path="/rivals/edit-rival/:id"
              exact={true}
              component={EditRival}
            ></Route>
            <Route
              path="/rivals/:id"
              exact={true}
              component={ViewRival}
            ></Route>
            <Route
              path="/rivals/:rivalid/view-player/:id"
              exact={true}
              component={ViewRivalPlayer}
            ></Route>
            <Route
              path="/rivals/:rivalid/edit-player/:id"
              exact={true}
              component={EditRivalPlayer}
            ></Route>
            <Route component={NotFoundPage}></Route>
          </Switch>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
