import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import firebase from "../../../../firebase/firebase";
import "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
  },
  headerButtonContainer: {
    display: "flex",
  },
  basicPadding: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  removeTextDecor: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  basicTextFieldPadding: {
    display: "flex",
    padding: "10px",
  },
}));

const EditClubDetails = () => {
  const classes = useStyles();
  const [loadingComponent, setLoadingComponent] = useState(true);
  const history = useHistory();
  const [saveChangesState, setSaveChangesState] = useState(false);
  const [clubName, setClubName] = useState("Arsenal");
  const [clubID, setClubID] = useState("123");
  const [userEmail, setUserEmail] = useState("prithvirajkumar14@gmail.com");
  const [clubNameLabel, setClubNameLabel] = useState("Update Club Name");
  const [clubeNameLabelErrorState, setClubNameLabelErrorState] =
    useState(false);

  useEffect(() => {
    let lsClubDetails = {
      clubName: "Loading...",
      lsClubID: "Arsenal",
      userEmail: "prithvirajkumar14@gmail.com",
    };

    const authState = localStorage.getItem("authState");
    const parsedAuthState = JSON.parse(authState);
    if (parsedAuthState) {
      lsClubDetails.clubName = parsedAuthState.clubName;
      lsClubDetails.lsClubID = parsedAuthState.clubID;
      lsClubDetails.userEmail = parsedAuthState.userEmail;
      setClubName(lsClubDetails.clubName);
      setClubID(lsClubDetails.lsClubID);
      setUserEmail(lsClubDetails.userEmail);
    }
    setLoadingComponent(false);
  }, []);

  const handleSavingChanges = (event) => {
    event.preventDefault();
    console.log("handleSavingChanges", clubName);
    if (clubName.trim() !== "") {
      console.log("clubname is empty");
      const updateClubNameInFirestore = async () => {
        const db = firebase.firestore();
        let docRef = db.collection("organisation").doc(clubID);

        docRef
          .update({
            ClubName: clubName,
          })
          .then(() => {
            console.log("Document successfully written!");
          })
          .catch((error) => console.log(error));
      };
      updateClubNameInFirestore();
      const updatedAuthState = {
        clubName: clubName,
        clubID: clubID,
        userEmail: userEmail,
      };
      localStorage.setItem("authState", JSON.stringify(updatedAuthState));
      history.push("/settings");
    } else {
      setClubNameLabel("Enter a Valid Club Name");
      setClubNameLabelErrorState(true);
    }
  };

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {!loadingComponent && (
        <div>
          <div className={classes.headerContainer}>
            <h1>Update Club Details</h1>
            <div className={classes.headerButtonContainer}>
              <div className={classes.basicPadding}>
                {saveChangesState && (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonHeight}
                    onClick={handleSavingChanges}
                    color="secondary"
                  >
                    Save Changes
                  </Button>
                )}
              </div>
              <div className={classes.basicPadding}>
                <Link className={classes.textDecorRemoveButton} to="/settings">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonHeight}
                  >
                    Cancel
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className={classes.gridContainer}>
            <Grid item xs={5}>
              <Paper className={classes.paper}>
                <form className={classes.form} noValidate>
                  <TextField
                    error={clubeNameLabelErrorState}
                    className={classes.basicTextFieldPadding}
                    variant="outlined"
                    margin="normal"
                    id="updateClubName"
                    label={clubNameLabel}
                    name="updateClubName"
                    autoComplete="text"
                    value={clubName}
                    onChange={(event) => {
                      setClubName(event.target.value);
                      setSaveChangesState(true);
                    }}
                    autoFocus
                  />
                </form>
              </Paper>
            </Grid>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditClubDetails;
