import React, { useState } from "react";
import { useHistory } from "react-router";
import validator from "validator";

// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
// import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import scoboardLogo from "../../../../src/assets/images/scoboard-logo-large.png";
import { useAuth } from "../../../contexts/AuthContext";
import { createClub } from "./signUpUtils/signUpUtils";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://scoboard.com/">
        scoboard.com
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  scoboardLogo: {
    width: "70%",
  },
}));

// Signup should only be possible for users with @scoboard.com

export default function SignUp() {
  const classes = useStyles();
  const { signup } = useAuth();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [emailLabel, setEmailLabel] = useState("Email Address");
  const [emailErrorState, setEmailErrorState] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordLabel, setPasswordLabel] = useState("Password");
  const [passwordErrorState, setPasswordErrorState] = useState(false);

  const [confimPassword, setConfirmPassword] = useState("");
  const [confirmPasswordLabel, setConfirmPasswordLabel] =
    useState("Confirm Password");
  const [confirmPasswordErrorState, setConfirmPasswordErrorState] =
    useState(false);

  const [firstName, setFirstName] = useState("");
  const [firstNameLabel, setFirstNameLabel] = useState("First Name");
  const [firstNameErrorState, setFirstNameErrorState] = useState(false);

  const [lastName, setLastName] = useState("");

  const [clubName, setClubName] = useState("");
  const [clubNameLabel, setClubNameLabel] = useState("Club Name");
  const [clubNameErrorState, setClubNameErrorState] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  let lsClubDetails = {
    clubName: "Loading...",
    lsClubID: "Arsenal",
    userEmail: "dummy@mail.com",
  };

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubDetails.clubName = parsedAuthState.clubName;
    lsClubDetails.lsClubID = parsedAuthState.clubID;
    lsClubDetails.userEmail = parsedAuthState.userEmail;
  }

  let userAuthState = false;

  if (parsedAuthState.userEmail === "prithvirajkumar14@gmail.com") {
    userAuthState = true;
  } else {
    history.push("/");
  }

  const signUpHandler = async (e) => {
    e.preventDefault();

    if (validator.isEmail(email) === false) {
      setEmailErrorState(true);
      setEmailLabel("Enter valid Email Address");
      return;
    }

    if (validator.isEmpty(firstName) === true) {
      setFirstNameErrorState(true);
      setFirstNameLabel("First Name is required");
      return;
    }

    if (validator.isEmpty(clubName) === true) {
      setClubNameErrorState(true);
      setClubNameLabel("Club Name is required");
      return;
    }

    if (password.length <= 6 || confimPassword.length <= 6) {
      setPasswordLabel("Password should be more than 6 characters");
      setConfirmPasswordLabel("Password should be more than 6 characters");
      setPasswordErrorState(true);
      setConfirmPasswordErrorState(true);
      return;
    }

    if (password !== confimPassword) {
      setPasswordLabel("Passwords do not match");
      setConfirmPasswordLabel("Passwords do not match");
      setPasswordErrorState(true);
      setConfirmPasswordErrorState(true);
      return;
    }

    try {
      setError("");
      setLoading(true);
      await signup(email, password);
      await createClub(clubName, email);

      history.push("/login");
    } catch {
      setError("Failed to create an account");
      console.log(lastName);
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <React.Fragment>
      {userAuthState ? (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <img
              src={scoboardLogo}
              alt="scoboardLogo"
              className={classes.scoboardLogo}
            />
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <form className={classes.form} noValidate onSubmit={signUpHandler}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label={firstNameLabel}
                    error={firstNameErrorState}
                    autoFocus
                    onChange={(event) => {
                      setFirstName(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="clubName"
                    label={clubNameLabel}
                    name="clubName"
                    autoComplete="cname"
                    error={clubNameErrorState}
                    onChange={(event) => {
                      setClubName(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label={emailLabel}
                    name="email"
                    autoComplete="email"
                    error={emailErrorState}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label={passwordLabel}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={passwordErrorState}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="confirmPassword"
                    label={confirmPasswordLabel}
                    type="password"
                    id="confirmPassword"
                    autoComplete="current-confirm-password"
                    error={confirmPasswordErrorState}
                    onChange={(event) => {
                      setConfirmPassword(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                className={classes.submit}
              >
                Sign Up
              </Button>
            </form>
          </div>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
      ) : (
        <h1>Blocked</h1>
      )}
    </React.Fragment>
  );
}
