import React, { useState, useEffect } from "react";
import firebase from "../../../firebase/firebase";
import "firebase/firestore";
import Chart from "chart.js";
import { Link } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  gridContainer: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  squadHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    boxSizing: "border-box",
    alignItems: "center",
  },
  textDecorRemoveButton: {
    textDecoration: "none",
    color: "white",
  },
  playerChartHidden: {
    visibility: "hidden",
  },
}));

const ViewPlayer = (props) => {
  const classes = useStyles();
  const [player, setPlayer] = useState({});
  const [loadingComponent, setLoadingComponent] = useState(true);

  let lsClubID = "Arsenal";

  const authState = localStorage.getItem("authState");
  const parsedAuthState = JSON.parse(authState);
  if (parsedAuthState) {
    lsClubID = parsedAuthState.clubID;
  }

  useEffect(() => {
    const fetchData = async () => {
      const db = firebase.firestore();
      let docRef = db
        .collection("organisation")
        .doc(lsClubID)
        .collection("Squad")
        .doc(props.match.params.id);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            // console.log("Document data:", doc.data());
            setPlayer(doc.data());
            setLoadingComponent(false);
          } else {
            console.log("No such document!");
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    };
    fetchData();
  }, [props, lsClubID]);

  let playerChart = React.createRef();

  useEffect(() => {
    const ctx = playerChart.current.getContext("2d");

    new Chart(ctx, {
      type: "radar",
      data: {
        labels: [
          "Aerial",
          "Attacking",
          "Defending",
          "Mental",
          "Physical",
          "Speed",
          "Technical",
          "Vision",
        ],
        datasets: [
          {
            label: "Attributes",
            backgroundColor: "rgba(00, 255, 00, 0.1)",
            borderColor: "#00FF00",
            borderWidth: 2,
            data: [
              player.aerial,
              player.attacking,
              player.defending,
              player.mental,
              player.physical,
              player.speed,
              player.technical,
              player.vision,
            ],
          },
        ],
      },
      options: {
        maintainAspectRatio: true,
        scale: {
          ticks: {
            beginAtZero: true,
            max: 20,
          },
        },
      },
    });
  }, [player, playerChart]);

  return (
    <React.Fragment>
      {loadingComponent && <LinearProgress></LinearProgress>}
      {!loadingComponent ? (
        <div>
          <div className={classes.squadHeaderContainer}>
            <h1>
              {player.firstName} {player.lastName}
            </h1>
            <Link
              className={classes.textDecorRemoveButton}
              to={"/squad/edit-player/" + props.match.params.id}
            >
              <Button
                variant="contained"
                color="secondary"
                className={classes.buttonHeight}
              >
                Edit Player
              </Button>
            </Link>
          </div>
          <div className={classes.gridContainer}>
            <Grid item xs={4}>
              <Paper>
                <List>
                  <ListItem>
                    <ListItemText>Aerial: {player.aerial}</ListItemText>
                  </ListItem>
                </List>
                <List>
                  <ListItem>
                    <ListItemText>Attacking: {player.attacking}</ListItemText>
                  </ListItem>
                </List>
                <List>
                  <ListItem>
                    <ListItemText>Defending: {player.defending}</ListItemText>
                  </ListItem>
                </List>
                <List>
                  <ListItem>
                    <ListItemText>Mental: {player.mental}</ListItemText>
                  </ListItem>
                </List>
                <List>
                  <ListItem>
                    <ListItemText>Physical: {player.physical}</ListItemText>
                  </ListItem>
                </List>
                <List>
                  <ListItem>
                    <ListItemText>Speed: {player.speed}</ListItemText>
                  </ListItem>
                </List>
                <List>
                  <ListItem>
                    <ListItemText>Technical: {player.technical}</ListItemText>
                  </ListItem>
                </List>
                <List>
                  <ListItem>
                    <ListItemText>Vision: {player.vision}</ListItemText>
                  </ListItem>
                </List>
              </Paper>
            </Grid>
            <Grid item xs={7}>
              <Paper className={classes.paper}>
                <canvas id="playerRadarChart" ref={playerChart} />
              </Paper>
            </Grid>
          </div>
        </div>
      ) : (
        <div className={classes.playerChartHidden}>
          <canvas id="playerRadarChart" ref={playerChart} />
        </div>
      )}
    </React.Fragment>
  );
};

export default ViewPlayer;
